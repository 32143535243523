import React from "react";

type TabContentProps = {
  page: string;
  activeTab: string;
  children: React.ReactNode;
};

export const TabContent: React.FC<TabContentProps> = ({
  page,
  activeTab,
  children,
}: TabContentProps): JSX.Element => {
  return (
    <>
      {activeTab === page && (
        <div className={`${page}-tab-content h-full`}>{children}</div>
      )}
    </>
  );
};
