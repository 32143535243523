import camelcaseKeys from "camelcase-keys";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { jwtDecode, JwtPayload } from "jwt-decode";
import React, { createElement } from "react";

import { ProgressBar } from "../components";
import { userSlice } from "../redux/slices/userSlice";
import { ReportType, Session } from "../types";
import {
  getAccessTokenSilently,
  isAuthenticated,
  logout,
  user,
} from "./Auth0Utility";
import { possibleMovementTypes } from "./Constants";

// Join together the strings in the class name
export const joinClassNames = (...classes: any[]): string => {
  return classes.filter(Boolean).join(" ");
};

// Return the time of day - morning, afternoon, or evening.
export function timeOfDay(): string {
  const currentHour: number = new Date().getHours();
  if (currentHour > 3 && currentHour < 12) {
    return "morning";
  } else if (currentHour > 12 && currentHour < 6) {
    return "afternoon";
  } else {
    return "evening";
  }
}

// Taken from https://stackoverflow.com/a/46774740/9406847
export const toTitleCase = (str: string): string => {
  const articles = ["a", "an", "the"];
  const conjunctions = ["for", "and", "nor", "but", "or", "yet", "so"];
  const prepositions = [
    "with",
    "at",
    "from",
    "into",
    "upon",
    "of",
    "to",
    "in",
    "for",
    "on",
    "by",
    "like",
    "over",
    "plus",
    "but",
    "up",
    "down",
    "off",
    "near",
  ];

  // The list of spacial characters can be tweaked here
  const replaceCharsWithSpace = (replaceStr: string) =>
    replaceStr.replace(/[^0-9\p{L}\-\.'&/\\]/u, " ").replace(/(\s\s+)/gi, " ");
  const capitalizeFirstLetter = (capitalizeStr: string) =>
    capitalizeStr.charAt(0).toUpperCase() + capitalizeStr.substr(1);
  const normalizeStr = (normalStr: string) => normalStr.toLowerCase().trim();
  const capitalizeHyphenated = (hyphenatedStr: string) =>
    hyphenatedStr
      .split("-")
      .map((word) => capitalizeFirstLetter(word))
      .join("-");
  const shouldCapitalize = (
    word: string,
    fullWordList: string[],
    posWithinStr: number,
  ) => {
    if (posWithinStr == 0 || posWithinStr == fullWordList.length - 1) {
      return true;
    }

    return !(
      articles.includes(word) ||
      conjunctions.includes(word) ||
      prepositions.includes(word)
    );
  };

  str = replaceCharsWithSpace(str);
  str = normalizeStr(str);
  str = capitalizeHyphenated(str);

  let words = str.split(" ");
  if (words.length <= 2) {
    // Strings less than 3 words long should always have first words capitalized
    words = words.map((w) => capitalizeFirstLetter(w));
  } else {
    for (let i = 0; i < words.length; i++) {
      words[i] = shouldCapitalize(words[i], words, i)
        ? capitalizeFirstLetter(words[i])
        : words[i];
    }
  }

  return words.join(" ");
};

// Used https://redux-toolkit.js.org/rtk-query/usage/automated-refetching for a guide for this method
// Helper method to return a list of results with the abstract LIST tag type and individual tags for each element
export function providesList<
  R extends { id: string | number }[],
  T extends string,
>(resultsWithIds: R | undefined, tagType: T): any[] {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

// As we need this to return many types that are different, we're using any as the return type
// This function will handle arrays of results as well, including nested objects using a recursive approach
export function camelCaseResponse(response: Record<string, unknown>): any {
  return camelcaseKeys(response, { deep: true });
}

const buildStatusContainer = (session: Session, player: any = undefined) => {
  let statusColor: string;
  let status: string;
  const statusDate = format(new Date(session.updatedAt), "M/d/yyyy, h:mm a");
  if (player) {
    statusColor =
      player.percentComplete === 100 && player.failedMovements === 0
        ? "text-green-600"
        : "text-red-500";
    status =
      player.percentComplete === 100 && player.failedMovements === 0
        ? "Completed"
        : player.inProgressMovements === 0 && player.failedMovements > 0
          ? "Some movements failed"
          : player.percentComplete === 0
            ? "Awaiting Processing"
            : "Processing";
  } else {
    statusColor =
      session.status === "completed" || session.status === "processed"
        ? "text-green-600"
        : session.status === "in-error"
          ? "text-[#F59E0B]"
          : "text-red-500";
    status =
      session.failedMovements > 0
        ? `Completed, ${session.failedMovements} rejected movements`
        : session.status === "completed" || session.status === "processed"
          ? "Completed"
          : toTitleCase(session.status);
  }
  const statusDateElement = createElement(
    "div",
    { className: "text-sm text-gray-900 pb-1 status-date" },
    statusDate.toLowerCase(),
  );
  const customStatusElement = createElement(
    "div",
    { className: `${statusColor} status-element` },
    status,
  );
  if (status === "In Progress") {
    return createElement(
      "div",
      { className: "flex flex-col pr-1 w-3/4" },
      <ProgressBar progress={session.percentComplete} />,
    );
  } else {
    return createElement(
      "div",
      {
        className:
          "flex flex-col pr-1 w-3/4 truncate hover:text-clip hover:whitespace-normal",
      },
      statusDateElement,
      customStatusElement,
    );
  }
};

export function parseCompletedSessions(
  data: Session[],
  playerReports: any[] = [],
): Session[] {
  const copiedData = [...data];
  copiedData.forEach((session, index) => {
    const typeAndVenue = `${session.sessionType.name}, ${session.sessionVenue}`;
    copiedData[index] = {
      ...session,
      statusComponent: buildStatusContainer(session),
      typeAndVenue: typeAndVenue,
      reports: playerReports
        ? playerReports.filter((report: any) => {
            if (report.sessionId == session.id) {
              return report;
            }
          })
        : [],
      defaultSortDate: format(new Date(session.sessionDate), "yyyy-MM-dd"),
      hand: determineHandsForReports(
        playerReports
          ? playerReports.filter((report: any) => {
              if (report.sessionId == session.id) {
                return report;
              }
            })
          : [],
      ),
    };
  });
  copiedData.sort((a: Session, b: Session): number => {
    return a.defaultSortDate > b.defaultSortDate ? -1 : 1;
  });
  return copiedData;
}

export function parseInProgressSessions(
  data: Session[],
  playerReports: any[] = [],
): Session[] {
  const copiedData = [...data];
  copiedData.forEach((session: Session, index) => {
    const statusElement = <ProgressBar progress={session.percentComplete} />;
    const typeAndVenue = `${session.sessionType.slug}, ${session.sessionVenue}`;
    copiedData[index] = {
      ...session,
      statusComponent: statusElement,
      typeAndVenue: typeAndVenue,
      reports: playerReports
        ? playerReports.filter((report: any) => {
            if (report.sessionId == session.id) {
              return report;
            }
          })
        : [],
      hand: determineHandsForReports(
        playerReports
          ? playerReports.filter((report: any) => {
              if (report.sessionId == session.id) {
                return report;
              }
            })
          : [],
      ),
    };
  });
  return copiedData.sort(function (a, b) {
    if (a.percentComplete > b.percentComplete) {
      return -1;
    } else {
      return 1;
    }
  });
}

export const parseDataForSessionDetails = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sessionPlayers: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selectedSession: any,
  sessionReports: any[] = [],
): any[] => {
  const copiedPlayers = [...sessionPlayers];
  copiedPlayers.forEach((player: any, index: number) => {
    copiedPlayers[index] = {
      ...player,
      playerName: `${player.firstName} ${player.lastName}`,
      numberOfMovements: determineNumberOfMovements(
        player.totalMovements,
        selectedSession.sessionType.slug,
      ),
      statusComponent: buildStatusContainer(selectedSession, player),
      uploadDate: selectedSession.createdAt,
      reports: sessionReports.filter((report: any) => {
        if (report.primaryPlayers[0].id == player.id) {
          return report;
        }
      }),
      sessionTableValues: {
        numberOfMovements: displayMovementCount(
          player.totalMovements,
          selectedSession.sessionType.slug,
        ),
      },
      hand: determineHandsForReports(
        sessionReports.filter((report: any) => {
          if (report.primaryPlayers[0].id == player.id) {
            return report;
          }
        }),
      ),
    };
  });
  return copiedPlayers;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const determineHandsForReports = (reports: any): string =>
  reports
    .map((report: ReportType) => {
      if (report.domHand === "RHA") {
        return "Right";
      } else if (report.domHand === "LHA") {
        return "Left";
      } else if (report.domHand === "SHA") {
        return "Switch";
      } else {
        return "";
      }
    })
    .join(", ");

export const determineNumberOfMovements = (
  totalMovements: {
    [key: string]: number;
  },
  sessionType: string,
): { [key: string]: number | undefined } => {
  if (sessionType === "movement-screen") {
    return {
      movements:
        totalMovements.movementScreens * totalMovements.movementsPerScreen,
    };
  } else {
    const sessionMovements: { [key: string]: number | undefined } = {};
    for (const [key, value] of Object.entries(totalMovements)) {
      sessionMovements[key] = value ?? undefined;
    }
    return sessionMovements;
  }
};

export const displayMovementCount = (
  totalMovements: {
    [key: string]: number;
  },
  sessionType: string,
  modal?: boolean,
): string => {
  if (sessionType === "movement-screen") {
    return modal
      ? `${totalMovements.movementScreens * totalMovements.movementsPerScreen} Movements`
      : `Movements: ${totalMovements.movementScreens * totalMovements.movementsPerScreen}`;
  } else {
    const movements = [];
    for (const [movement, count] of Object.entries(totalMovements)) {
      if (modal) {
        movements.push(
          `${count} ${possibleMovementTypes[movement] || "Movements"}`,
        );
      } else {
        movements.push(
          `${possibleMovementTypes[movement] || "Movements"}: ${count}`,
        );
      }
    }
    return movements.join(", ");
  }
};

export const disableAddPlayer = (orgType: string): boolean => {
  if (orgType === "NBA") {
    return true;
  } else {
    return false;
  }
};

export const disableEditPlayer = (orgType: string): boolean => {
  if (orgType === "NBA") {
    return true;
  } else {
    return false;
  }
};

export const showPlayerHandedness = (orgType: string): boolean => {
  if (orgType === "NBA") {
    return false;
  } else {
    return true;
  }
};

export const disableCustomAnalysis = (orgType: string): boolean => {
  if (orgType === "NBA") {
    return true;
  } else {
    return false;
  }
};

interface CustomJwtPayload extends JwtPayload {
  permissions?: string[];
}

export async function fetchAccessToken() {
  const { setUserAuth } = userSlice.actions;

  if (isAuthenticated && user) {
    try {
      const accessToken = await getAccessTokenSilently();
      const decodedToken = jwtDecode<CustomJwtPayload>(accessToken);
      const permissions = decodedToken.permissions ?? [];

      setUserAuth({ token: accessToken, permissions: permissions });
      return accessToken;
    } catch (e) {
      console.log("access token error", e);
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
  }
}

export const formatMovementType = (movementType: string): string => {
  const type = movementType
    .replace(/-/g, " ")
    .replace(/baseball\s*|basketball\s*/gi, "");
  return toTitleCase(type);
};

export async function downloadS3Files(
  urls: string[],
  toZip: boolean = false,
  zipFileName: string = `reboot_motion_archive_${Date.now()}.zip`,
) {
  try {
    const files = await fetchFiles(urls);
    if (toZip) {
      const zip = new JSZip();
      files.forEach(({ fileName, blob }) => {
        zip.file(fileName, blob);
      });
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, zipFileName);
    } else {
      files.forEach(({ fileName, blob }) => {
        saveAs(blob, fileName);
      });
    }
  } catch (error) {
    console.error("Error downloading files:", error);
  }
}

async function fetchFiles(
  urls: string[],
): Promise<{ fileName: string; blob: Blob }[]> {
  const fetchFile = async (url: string) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`);
    }
    return response.blob();
  };

  return Promise.all(
    urls.map(async (url) => {
      const blob = await fetchFile(url);
      const fileName = getFileNameFromPresignedUrl(url);
      return { fileName, blob };
    }),
  );
}

function getFileNameFromPresignedUrl(url: string): string {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  return pathname.substring(pathname.lastIndexOf("/") + 1);
}
