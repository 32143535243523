import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";

import {
  ColumnOneTableCellSkeleton,
  IconButton,
  Table,
  TableCellSkeleton,
} from "../../../components";
import { columnHelper } from "../../../components/shared/SharedSessionColumns";
import { filterDateColumn } from "../../../components/Table/filters/DateFilter";
import { useGetFlaggedReportsQuery } from "../../../services";
import { useReportsTableFilters } from "../../../shared/Constants";
import { downloadS3Files, formatMovementType } from "../../../shared/Functions";

export const FlaggedReports: React.FC = (): JSX.Element => {
  // State and AppContext
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("completedSessionPageSize") || "10",
  );

  const { data: flaggedReports, isFetching: fetchingReports } =
    useGetFlaggedReportsQuery();

  const tableData = useMemo(
    () => (fetchingReports ? Array(10).fill({}) : flaggedReports),
    [fetchingReports, flaggedReports],
  );

  const flaggedReportsColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        enableSorting: false,
        size: 500,
        cell: function nameCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="flagged-report-name"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("movementType", {
        header: "Movement Type",
        enableSorting: false,
        cell: function movementTypeCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="flagged-report-movement-type"
            >
              {formatMovementType(element.getValue())}
            </p>
          );
        },
      }),
      columnHelper.accessor("totalFlags", {
        header: "# of Flags",
        enableSorting: false,
        cell: function numFlagsCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="flagged-report-total-flags"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Created",
        sortingFn: "datetime",
        filterFn: filterDateColumn,
        cell: function dateCell(element: any) {
          return (
            <div
              className="font-medium text-gray-900 cursor-default"
              data-testid="flagged-report-date-created"
            >
              {format(new Date(element.getValue()), "M/d/yyyy")}
            </div>
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "",
        enableSorting: false,
        cell: function actionsCell(element: any) {
          const reportS3Url = element.row.original.reportS3Url;
          return (
            <div
              className="flex justify-end pr-8"
              data-testid="flagged-report-actions-container"
            >
              <a
                href={reportS3Url}
                target="_blank"
                rel="noreferrer"
                data-testid="view-flagged-report-link"
                className="mr-4"
              >
                <IconButton>
                  <EyeIcon className="h-5 w-5 stroke-2" />
                </IconButton>
              </a>
              <IconButton
                onClick={() => downloadS3Files([reportS3Url])}
                data-testid="download-flagged-report-button"
              >
                <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
              </IconButton>
            </div>
          );
        },
      }),
    ],
    [],
  );

  const tableColumns = useMemo(
    () =>
      fetchingReports
        ? flaggedReportsColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : flaggedReportsColumns,
    [fetchingReports, flaggedReportsColumns],
  );

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full h-full mt-8">
      <div
        className="block min-w-max"
        data-testid="flagged-reports-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="flagged-reports-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingReports}
          customRowClassName="flagged-reports-table-row"
          filters={useReportsTableFilters()}
        />
      </div>
    </main>
  );
};
