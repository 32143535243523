import * as Sentry from "@sentry/react";

import {
  camelCaseResponse,
  determineNumberOfMovements,
  displayMovementCount,
  providesList,
} from "../shared/Functions";
import { Session, SessionType } from "../types";
import { emptyApi } from "./emptyApi";

export const sessionExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSessionById: builder.query<Session, string>({
      query: (id) => `/session/${id}`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (result, error, id) => [{ type: "Sessions", id }],
    }),
    getSessionsByPlayerId: builder.query<
      Session[],
      { orgPlayerId: string; limit: number; offset: number }
    >({
      query: (args) => {
        const { orgPlayerId, limit, offset } = args;
        return {
          url: "/sessions",
          params: {
            org_player_ids: orgPlayerId,
            limit,
            offset,
          },
        };
      },
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getAllSessions: builder.query<Session[], void>({
      query: () => "/sessions?offset=0&limit=1000",
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionsByStatus: builder.query<
      Session[],
      { status: string; limit?: number; offset: number }
    >({
      query: (args) => {
        const { status, limit, offset } = args;
        return {
          url: `/sessions`,
          params: { status, limit, offset },
        };
      },
      transformResponse: (response: any) => {
        return processRetrievedSessions(camelCaseResponse(response));
      },
      providesTags: (results) => providesList(results, "Sessions"),
    }),
    getSessionTypes: builder.query<SessionType[], void>({
      query: () => "/session_types",
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
  }),
});

export const {
  useGetSessionByIdQuery,
  useGetSessionsByPlayerIdQuery,
  useGetAllSessionsQuery,
  useGetSessionsByStatusQuery,
  useGetSessionTypesQuery,
} = sessionExtendedApi;

function processRetrievedSessions(camelCasedResponse: any): Session[] {
  let sessions: Session[] = [];
  try {
    sessions = camelCasedResponse.map((session: any) => {
      return {
        id: session.id,
        sessionType: {
          id: session.sessionType.id,
          slug: session.sessionType.slug.includes("-")
            ? session.sessionType.slug.replace(/-/g, " ")
            : session.sessionType.slug,
          name: session.sessionType.name,
        },
        mocapType: {
          id: session.mocapType.id,
          slug: session.mocapType.slug,
        },
        numberOfMovements: determineNumberOfMovements(
          session.totalMovements,
          session.sessionType.slug,
        ),
        status:
          session.failedMovements > 20
            ? "in-error"
            : session.percentComplete > 99
              ? "completed"
              : "in-progress",
        officialGameId: session.officialGameId ?? 0,
        sessionStart: session.sessionStart ?? "unknown",
        sessionComplete: session.session_complete ?? "unknown",
        sessionDate: new Date(`${session.sessionDate}T00:00:00`).toISOString(),
        sessionVenue: session.sessionVenue ?? "default",
        uploadDate: session.createdAt,
        percentComplete: Math.round(session.percentComplete),
        failedMovements:
          session.failedMovements > 20 ? session.failedMovements : 0,
        sessionTableValues: {
          numberOfMovements: displayMovementCount(
            session.totalMovements,
            session.sessionType.slug,
          ),
        },
        sessionId: session.officialGameId ? session.officialGameId : session.id,
        updatedAt: session.updatedAt,
      };
    });
  } catch (err) {
    console.warn(
      "There was an issue with processing the returned values: ",
      camelCasedResponse,
    );
    console.warn("The error was: ", err);
    Sentry.captureException(err);
  }

  return sessions;
}
