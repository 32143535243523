import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  ColumnOneTableCellSkeleton,
  PrimaryButton,
  StepType,
  Table,
  TableCellSkeleton,
} from "../../../components";
import { SharedSessionColumns } from "../../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../../context";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { playerSlice } from "../../../redux/slices/playerSlice";
import {
  useGetPlayerByIdQuery,
  useGetSessionsByStatusQuery,
} from "../../../services";
import {
  uploadSessionStepList,
  useSessionsTableFilters,
} from "../../../shared/Constants";
import { parseInProgressSessions } from "../../../shared/Functions";
import { Org, Session } from "../../../types";

export const ProcessingSessions: React.FC = (): JSX.Element => {
  // State and AppContext
  const {
    setModalStepList,
    setModalState,
    setModalTitle,
    setDisplayBack,
    setCurrentStep,
    playerIdToRetrieve,
    setPlayerIdToRetrieve,
  } = useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("processedSessionPageSize") || "10",
  );

  // Redux calls
  const orgData: Org = useAppSelector((state) => state.org);
  const sessionFormValues = useAppSelector((state) => state.uploadSessionForm);
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const pendingSessionCount = orgData.sessions.pending;

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;

  // RTK Queries and Mutations
  const { data: pendingSessions, isFetching: fetchingSessions } =
    useGetSessionsByStatusQuery(
      {
        status: "pending",
        limit: pendingSessionCount,
        offset: 0,
      },
      { skip: pendingSessionCount === 0 },
    );
  const { data: singlePlayer } = useGetPlayerByIdQuery(playerIdToRetrieve, {
    skip: !playerIdToRetrieve,
  });

  useEffect(() => {
    if (singlePlayer && storedPlayerList) {
      const updatedPlayerList = storedPlayerList
        .filter((player) => player.orgPlayerId !== playerIdToRetrieve)
        .concat(singlePlayer);
      dispatch(storePlayers(updatedPlayerList));
      setPlayerIdToRetrieve("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePlayer]);

  // Data manipulation and parsing
  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  /** Sorts the completed session list by percentage of completion in reverse order by default, highest percentage to lowest percentage */
  const processingSessionsList = parseInProgressSessions(
    pendingSessions || [],
  ).sort((a: Session, b: Session): number => {
    return a.percentComplete > b.percentComplete ? -1 : 1;
  });

  const tableData = useMemo(
    () => (fetchingSessions ? Array(10).fill({}) : processingSessionsList),
    [fetchingSessions, processingSessionsList],
  );

  const tableColumns = useMemo(
    () =>
      fetchingSessions
        ? memoizedSharedSessionColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : memoizedSharedSessionColumns,
    [fetchingSessions, memoizedSharedSessionColumns],
  );

  const stepList: StepType[] = useMemo((): StepType[] => {
    if (orgData && storedPlayerList) {
      return uploadSessionStepList({
        playerList: storedPlayerList,
        sessionFormValues: sessionFormValues,
        orgData: orgData,
      });
    } else {
      return [];
    }
  }, [orgData, storedPlayerList, sessionFormValues]);

  const openModal = () => {
    const stepTitleNode = document.querySelector("#step-1-title");
    if (stepTitleNode) {
      stepTitleNode.innerHTML = `<div>Select/Add Player</div>`;
    }
    setModalStepList(stepList);
    setModalTitle("Upload Session");
    setCurrentStep(1);
    setModalState(true);
  };

  const checkIfNextButtonIsEnabled = useCallback(() => {
    if (stepList.length && !stepList[0].disableNext) {
      setModalStepList(stepList);
    }
  }, [setModalStepList, stepList]);

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
    if (selectedPageSize) {
      sessionStorage.setItem("processedSessionPageSize", selectedPageSize);
    }
    checkIfNextButtonIsEnabled();
  }, [checkIfNextButtonIsEnabled, selectedPageSize, setDisplayBack]);

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      <div className="mt-8">
        <div className="max-w-8xl mx-auto flex justify-between items-center pb-2">
          <div className="flex justify-start">
            <h2
              className="mt-4 px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
              data-testid="processing-sessions-header"
            >
              Sessions Processing
            </h2>
          </div>
          <PrimaryButton
            className="mr-8"
            onClick={() => openModal()}
            data-testid="upload-player-data-button"
          >
            Upload Player Data
          </PrimaryButton>
        </div>

        <div
          className="block min-w-max mt-3"
          data-testid="processing-sessions-table-container"
        >
          <Table
            columns={tableColumns}
            data={tableData}
            data-testid="processing-sessions-table"
            selectedPageSize={selectedPageSize}
            setSelectedPageSize={setSelectedPageSize}
            displayFooter={true}
            fetching={fetchingSessions}
            customRowClassName="processing-sessions-table-row"
            filters={useSessionsTableFilters()}
          />
        </div>
      </div>
    </main>
  );
};
