import tw from "tailwind-styled-components";

const Button = tw.button`
  px-4 py-2
  border
  border-transparent
  rounded-md
  shadow-sm
  text-sm
  font-bold
  disabled:cursor-not-allowed
  disabled:text-[#CACCCF]
`;

export const PrimaryButton = tw(Button)`
  bg-secondary-800
  text-white
  hover:bg-[#FF7A1F]
  active:bg-[#E36006]
  disabled:bg-[#F9F9FA]
`;

export const SecondaryButton = tw(Button)`
  border-secondary-800
  text-secondary-800
  hover:text-white
  hover:bg-[#FF7A1F]
  active:bg-[#E36006]
  disabled:border-[#F9F9FA]
  disabled:hover:bg-[white]
`;

export const BlankButton = tw(Button)`
  text-gray-700
  border-[#D1D5DB]
  bg-white
  hover:bg-[#F5A623]/[0.06]
  hover:text-[#FB6A07]
  active:bg-[#FF7A1F]/[0.12]
  active:text-[#FB6A07]
  disabled:hover:bg-white
`;

export const RedButton = tw(Button)`
  text-[#D0021B]
  border-[#D0021B]
  bg-white
  hover:bg-[#D0021B]/[0.06]
  hover:text-[#D0021B]
`;

export const AnalysisButton = tw(Button)`
  text-secondary-800
  border-[#D1D5DB]
  font-normal
  hover:bg-[#F5A623]/[0.06]
  hover:text-[#FB6A07]
  active:bg-[#E36006]
  active:bg-[#FF7A1F]/[0.12]
  active:text-[#FB6A07]
  disabled:hover:bg-[#F9F9FA]
`;

export const IconButton = tw(Button)`
  p-2
  border-[#D1D5DB]
  text-[#FB6A07]
  hover:bg-[#F5A623]/[0.06]
  active:bg-[#FF7A1F]/[0.12]
  active:text-[#FB6A07]
  disabled:hover:bg-white
`;
