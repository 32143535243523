import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";

import { MobileHomeDashboard, QuickActions } from "..";
import {
  ColumnOneTableCellSkeleton,
  StepType,
  Table,
  TableCellSkeleton,
} from "../../components";
import { SharedSessionColumns } from "../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../context";
import { PlayerForm } from "../../features/Player/PlayerForm";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { orgSlice } from "../../redux/slices/orgSlice";
import { playerSlice } from "../../redux/slices/playerSlice";
import {
  useGetAllPlayersQuery,
  useGetOrgInfoQuery,
  useGetOrgMovementTypesAndTagsQuery,
  useGetPlayerByIdQuery,
  useGetSessionsByStatusQuery,
  useGetSessionTypesQuery,
} from "../../services";
import {
  emptyPlayerFormValues,
  playerListLimit,
  uploadSessionStepList,
} from "../../shared/Constants";
import {
  parseCompletedSessions,
  parseInProgressSessions,
} from "../../shared/Functions";
import { ApiMovementTypesAndTags, MovementType, Player } from "../../types";

export const HomeDashboard: React.FC = (): JSX.Element => {
  // State and AppContext
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("homeDashboardPageSize") || "10",
  );
  const {
    setModalStepList,
    setModalState,
    setModalTitle,
    setDisplayBack,
    setModalSingleStep,
    playerIdToRetrieve,
    setPlayerIdToRetrieve,
    loadingDashboard,
    setLoadingDashboard,
  } = useAppContext();
  const [offsetForPlayerList, setOffsetForPlayerList] = useState(0);
  const [combinedPlayers, setCombinedPlayers] = useState<Player[]>([]);
  const [playerQueryCount, setPlayerQueryCount] = useState(0);

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );
  const orgData = useAppSelector((state) => state.org);
  const storedPlayerList = useAppSelector((state) => state.players.playerList);
  const pendingSessionCount = orgData.sessions.pending;

  const playerCount = orgData.players.total;
  const numberOfQueriesForPlayers = Math.ceil(playerCount / playerListLimit);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;
  const { setOrgInformation, updateOrgInformation } = orgSlice.actions;

  // RTK Queries
  const { data: org, isFetching: fetchingOrgInfo } =
    useGetOrgInfoQuery(undefined);
  const { data: orgMovements, isFetching: fetchingOrgMovements } =
    useGetOrgMovementTypesAndTagsQuery(orgData.id, {
      skip:
        orgData.details.movementTypes &&
        orgData.details.movementTypes.length > 0,
    });
  const { data: sessionTypes, isFetching: fetchingSessionTypes } =
    useGetSessionTypesQuery(undefined, {
      skip:
        orgData.details.sessionTypes && orgData.details.sessionTypes.length > 0,
    });
  const { data: pendingSessions, isFetching: fetchingPending } =
    useGetSessionsByStatusQuery(
      {
        status: "pending",
        limit: isBrowser ? 3 : pendingSessionCount,
        offset: 0,
      },
      { skip: pendingSessionCount === 0 },
    );
  const { data: processedSessions, isFetching: fetchingProcessed } =
    useGetSessionsByStatusQuery({
      status: "processed",
      limit: 7,
      offset: 0,
    });
  const {
    data: playerList,
    originalArgs,
    isFetching: fetchingPlayers,
  } = useGetAllPlayersQuery(
    {
      offset: offsetForPlayerList,
      limit: playerListLimit,
    },
    {
      skip:
        storedPlayerList.length === playerCount ||
        playerQueryCount === numberOfQueriesForPlayers,
    },
  );
  const { data: singlePlayer } = useGetPlayerByIdQuery(playerIdToRetrieve, {
    skip: !playerIdToRetrieve,
  });

  useEffect(() => {
    if (fetchingOrgInfo || fetchingOrgMovements || fetchingSessionTypes) {
      setLoadingDashboard(true);
    } else if (fetchingPlayers && storedPlayerList.length !== playerCount) {
      setLoadingDashboard(true);
    } else {
      setLoadingDashboard(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchingOrgMovements,
    fetchingSessionTypes,
    playerCount,
    storedPlayerList.length,
    fetchingOrgInfo,
  ]);

  useEffect(() => {
    if (org && orgData.id === "") {
      dispatch(setOrgInformation(org));
    } else if (org && org.players !== orgData.players) {
      dispatch(updateOrgInformation({ players: org.players }));
    } else if (org && org.sessions !== orgData.sessions) {
      dispatch(updateOrgInformation({ sessions: org.sessions }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org, orgData]);

  useEffect(() => {
    if (sessionTypes) {
      dispatch(updateOrgInformation({ sessionTypes: sessionTypes }));
    } else if (orgMovements) {
      const movementTagsAndTypes = orgMovements.map(
        (item: ApiMovementTypesAndTags): MovementType => {
          return {
            id: item.movementType.id,
            name: item.movementType.name,
            slug: item.movementType.slug,
            tags: item.movementTags,
          };
        },
      );
      dispatch(updateOrgInformation({ movementTypes: movementTagsAndTypes }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTypes, orgMovements]);

  // Data processing and parsing
  const inProgressSessionList = parseInProgressSessions(pendingSessions || []);
  const completedSessionList = parseCompletedSessions(processedSessions || []);
  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  const completedTableData = useMemo(
    () => (fetchingProcessed ? Array(7).fill({}) : completedSessionList),
    [fetchingProcessed, completedSessionList],
  );

  const completedTableColumns = useMemo(
    () =>
      fetchingProcessed
        ? memoizedSharedSessionColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : memoizedSharedSessionColumns,
    [fetchingProcessed, memoizedSharedSessionColumns],
  );

  const skeletonTableColumns = useMemo(
    () =>
      memoizedSharedSessionColumns.map((column, index) => ({
        ...column,
        header: <TableCellSkeleton />,
        cell:
          index === 0 ? <ColumnOneTableCellSkeleton /> : <TableCellSkeleton />,
        enableSorting: false,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (playerList && originalArgs) {
      if (playerList.length === playerListLimit) {
        setOffsetForPlayerList(originalArgs.offset + playerListLimit);
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setPlayerQueryCount(playerQueryCount + 1);
      } else if (playerList.length < playerListLimit) {
        setCombinedPlayers(combinedPlayers.concat(playerList));
        setOffsetForPlayerList(0);
        setPlayerQueryCount(playerQueryCount + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerList]);

  useEffect(() => {
    if (storedPlayerList.length !== playerCount && combinedPlayers) {
      dispatch(storePlayers(combinedPlayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedPlayers]);

  useEffect(() => {
    if (singlePlayer && storedPlayerList) {
      const updatedPlayerList = storedPlayerList
        .filter((player) => player.orgPlayerId !== playerIdToRetrieve)
        .concat(singlePlayer);
      dispatch(storePlayers(updatedPlayerList));
      setPlayerIdToRetrieve("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singlePlayer]);

  const stepList: StepType[] = useMemo((): StepType[] => {
    if (orgData && uploadSessionFormValues) {
      return uploadSessionStepList({
        playerList: storedPlayerList,
        sessionFormValues: uploadSessionFormValues,
        orgData: orgData,
      });
    } else {
      return [];
    }
  }, [orgData, uploadSessionFormValues, storedPlayerList]);

  const openModal = (modalType: string, title?: string) => {
    if (modalType === "upload") {
      setModalSingleStep(undefined);
      const stepTitleNode = document.querySelector("#step-1-title");
      if (stepTitleNode) {
        stepTitleNode.innerHTML = `<div>Select/Add Player</div>`;
      }
      setModalStepList(stepList);
      setModalTitle("Upload Data");
      setModalState(true);
    } else if (modalType === "addPlayer" && title) {
      const step = <PlayerForm formValues={emptyPlayerFormValues} />;
      setModalSingleStep(step);
      setModalTitle(title);
      setModalState(true);
    }
  };

  const checkIfNextButtonIsEnabled = useCallback(() => {
    if (stepList.length && !stepList[0].disableNext) {
      setModalStepList(stepList);
    }
  }, [setModalStepList, stepList]);

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
    checkIfNextButtonIsEnabled();
  }, [setDisplayBack, checkIfNextButtonIsEnabled]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div
        className="hidden lg:block mt-8 px-4"
        data-testid="desktop-home-dashboard-container"
      >
        <QuickActions
          loadingDashboard={loadingDashboard}
          openModal={() => openModal("upload")}
        />
        <div data-testid="recently-completed-sessions-container">
          <div className="max-w-8xl mx-auto justify-between items-center pb-2 pt-8">
            {loadingDashboard ? (
              <div
                className="mt-4 mx-4 h-12 w-96 rounded-lg bg-gray-200 animate-pulse sm:mx-6 lg:mx-8 lg:mb-6"
                data-testid="skeleton-header"
              ></div>
            ) : (
              <div className="flex justify-start items-center">
                <h2
                  className="mt-4 px-4 text-3xl leading-6 font-bold text-gray-900 sm:px-6 lg:px-8 lg:pb-6"
                  data-testid="recently-completed-sessions-header"
                >
                  Recently Completed Sessions
                </h2>
                <Link
                  to="/completed-sessions"
                  className="text-blue-800 hover:text-blue-600"
                  data-testid="recently-completed-sessions-view-all-link"
                >
                  View All
                </Link>
              </div>
            )}
          </div>
          {loadingDashboard ? (
            <div data-testid="skeleton-table">
              <Table
                columns={skeletonTableColumns}
                data={completedTableData}
                selectedPageSize={selectedPageSize}
                displayFooter={false}
                fetching={true}
                hideSearch={true}
              />
            </div>
          ) : (
            <div
              className="min-w-max"
              data-testid="recently-completed-sessions-table-container"
            >
              <Table
                columns={completedTableColumns}
                data={completedTableData}
                data-testid="recently-completed-sessions-table"
                selectedPageSize={selectedPageSize}
                setSelectedPageSize={setSelectedPageSize}
                displayFooter={false}
                fetching={fetchingProcessed}
                customRowClassName="recently-completed-sessions-row"
                hideSearch={true}
              />
            </div>
          )}
        </div>
      </div>

      <div className="lg:hidden" data-testid="mobile-home-dashboard-container">
        <MobileHomeDashboard
          fetchingPending={fetchingPending}
          inProgressSessionList={inProgressSessionList}
          openModal={() => openModal("upload")}
          fetchingPlayers={fetchingPlayers}
        />
      </div>
    </main>
  );
};
