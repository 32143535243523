import { format, parse } from "date-fns";

import {
  handOptions,
  movementTypeOptions,
} from "../features/CustomAnalysis/CustomAnalysisForm";
import { camelCaseResponse, providesList } from "../shared/Functions";
import { PlayerGroupSegmentCreationData, SegmentDetails } from "../types";
import { emptyApi } from "./emptyApi";

type ApiPitchType = {
  pitchTypes: string[];
  mocapPitchTypes: string[];
};

type CustomAnalysisDetails = {
  movementTypeId: number;
  hand: string;
};

type CustomAnalysisFormData = {
  details: CustomAnalysisDetails;
  segmentData: SegmentDetails;
};

type ApiSegmentCriteria = {
  movementTypeId: number;
  domHand: string;
  sessionDate?: string[];
  mocapTypeId?: number;
  orgPlayerIds?: string[];
  pitchTypes?: string[];
  mocapPitchTypes?: string[];
  horizontalBreak?: string[];
  inducedVerticalBreak?: string[];
  relSpeedMph?: string[];
  verticalBreak?: string[];
};

export const customAnalysisExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequestedAnalyses: builder.query<any, void>({
      query: () => "/requested_analyses",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "RequestedAnalyses"),
    }),
    getRequestedAnalysisById: builder.query<any, number>({
      query: (id: number) => `/requested_analyses/${id}`,
      transformResponse: (response: any) =>
        parseRetrievedAnalysis(camelCaseResponse(response)),
    }),
    getPitchTypes: builder.query<ApiPitchType, number>({
      query: (id: number) => `/pitch_types?movement_type_id=${id}`,
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    createPlayerGroupSegment: builder.mutation<any, CustomAnalysisFormData>({
      query: ({ details, segmentData }) => ({
        url: "/player_group_segments",
        method: "POST",
        body: processPlayerGroupSegmentData(details, segmentData),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    updatePlayerGroupSegment: builder.mutation({
      query: ({ id, details, segmentData }) => ({
        url: `/player_group_segments/${id}`,
        method: "PATCH",
        body: processPlayerGroupSegmentData(details, segmentData),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    createRequestedAnalysis: builder.mutation({
      query: (body) => ({
        url: "/requested_analyses",
        method: "POST",
        body,
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["RequestedAnalyses"],
    }),
  }),
});

export const {
  useGetRequestedAnalysesQuery,
  useGetRequestedAnalysisByIdQuery,
  useGetPitchTypesQuery,
  useCreatePlayerGroupSegmentMutation,
  useUpdatePlayerGroupSegmentMutation,
  useCreateRequestedAnalysisMutation,
} = customAnalysisExtendedApi;

const processPlayerGroupSegmentData = (
  details: CustomAnalysisDetails,
  segmentData: SegmentDetails,
) => {
  const requestBody: PlayerGroupSegmentCreationData = {
    movement_type_id: details.movementTypeId,
    dom_hand: details.hand,
  };
  const playerField = segmentData.criteria.find(
    (criterion) => criterion.field.value === "player",
  );
  const playerGroup = segmentData.criteria.find(
    (criterion) => criterion.field.value === "playerGroup",
  );
  const pitchTypes = segmentData.criteria.find(
    (criterion) => criterion.field.value === "pitchType",
  );
  const mocapPitchTypes = segmentData.criteria.find(
    (criterion) => criterion.field.value === "mocapPitchType",
  );
  const mocapType = segmentData.criteria.find(
    (criterion) => criterion.field.value === "mocapType",
  );
  const horizontalBreak = segmentData.criteria.filter(
    (criterion) => criterion.field.value === "horizontalBreak",
  );
  const inducedVerticalBreak = segmentData.criteria.filter(
    (criterion) => criterion.field.value === "inducedVerticalBreak",
  );
  const releaseSpeed = segmentData.criteria.filter(
    (criterion) => criterion.field.value === "releaseSpeed",
  );
  const verticalBreak = segmentData.criteria.filter(
    (criterion) => criterion.field.value === "verticalBreak",
  );

  const convertInchesToFeet = (inches: string) => {
    return Number(inches) / 12;
  };

  const processPitchCharacteristics = (pitchCharValues: any[]) => {
    if (pitchCharValues.length > 1) {
      const comparator1 = pitchCharValues[0].comparison.label;
      const value1 = pitchCharValues[0].field.value.includes("releaseSpeed")
        ? pitchCharValues[0].option.value
        : convertInchesToFeet(pitchCharValues[0].option.value);
      const pitchChar1 = comparator1 + value1;

      const comparator2 = pitchCharValues[1].comparison.label;
      const value2 = pitchCharValues[1].field.value.includes("releaseSpeed")
        ? pitchCharValues[1].option.value
        : convertInchesToFeet(pitchCharValues[1].option.value);
      const pitchChar2 = comparator2 + value2;
      const pitchCharRange = [pitchChar1, pitchChar2];
      return pitchCharRange;
    } else {
      const comparator = pitchCharValues[0].comparison.label;
      const value = pitchCharValues[0].field.value.includes("releaseSpeed")
        ? pitchCharValues[0].option.value
        : convertInchesToFeet(pitchCharValues[0].option.value);
      const singlePitchChar = [comparator + value];
      return singlePitchChar;
    }
  };

  if (segmentData.dateRange && segmentData.dateRange.length > 0) {
    Object.assign(requestBody, {
      session_date: segmentData.dateRange,
    });
  }
  if (segmentData.individualDates && segmentData.individualDates.length > 0) {
    Object.assign(requestBody, {
      session_date_in: segmentData.individualDates,
    });
  }
  if (playerField) {
    const players: any = playerField.option;
    if (!requestBody.org_player_ids) {
      Object.assign(requestBody, {
        org_player_ids: players.map((player: any) => player.id),
      });
    } else {
      requestBody.org_player_ids = requestBody.org_player_ids.concat(
        players.map((player: any) => player.id),
      );
    }
  }
  if (playerGroup) {
    const orgPlayerIds = playerGroup.option.id;
    if (!requestBody.org_player_ids) {
      Object.assign(requestBody, {
        org_player_ids: orgPlayerIds.map((playerId: string) => playerId),
      });
    } else {
      requestBody.org_player_ids = requestBody.org_player_ids.concat(
        orgPlayerIds.map((playerId: string) => playerId),
      );
    }
  }
  if (pitchTypes) {
    const pitchTypeOptions: any = pitchTypes.option;
    Object.assign(requestBody, {
      pitch_types: Array.isArray(pitchTypeOptions)
        ? pitchTypeOptions.map((option: any) => option.value)
        : [pitchTypeOptions.value],
    });
  }
  if (mocapPitchTypes) {
    const mocapPitchTypeOptions: any = mocapPitchTypes.option;
    Object.assign(requestBody, {
      mocap_pitch_types: Array.isArray(mocapPitchTypeOptions)
        ? mocapPitchTypeOptions.map((option: any) => option.value)
        : [mocapPitchTypeOptions.value],
    });
  }
  if (mocapType) {
    const mocapTypeId = mocapType.option.id;
    Object.assign(requestBody, {
      mocap_type_id: mocapTypeId,
    });
  }
  if (releaseSpeed.length > 0) {
    const relSpeedValue = processPitchCharacteristics(releaseSpeed);
    Object.assign(requestBody, {
      rel_speed_mph: relSpeedValue,
    });
  }
  if (horizontalBreak.length > 0) {
    const horizBreakValue = processPitchCharacteristics(horizontalBreak);
    Object.assign(requestBody, {
      horizontal_break: horizBreakValue,
    });
  }
  if (verticalBreak.length > 0) {
    const vertBreakValue = processPitchCharacteristics(verticalBreak);
    Object.assign(requestBody, {
      vertical_break: vertBreakValue,
    });
  }
  if (inducedVerticalBreak.length > 0) {
    const indVertBreakValue = processPitchCharacteristics(inducedVerticalBreak);
    Object.assign(requestBody, {
      induced_vertical_break: indVertBreakValue,
    });
  }
  return requestBody;
};

const parseRetrievedAnalysis = (analysis: any) => {
  const primarySegment = analysis.playerGroupSegments.find((segment: any) => {
    return segment.segmentType === "primary";
  });
  const populationSegment = analysis.playerGroupSegments.find(
    (segment: any) => {
      return segment.segmentType === "population";
    },
  );
  const comparisonSegment = analysis.playerGroupSegments.find(
    (segment: any) => {
      return segment.segmentType === "comparison";
    },
  );
  const movementType = movementTypeOptions.find((type: { id: number }) => {
    return type.id === analysis.movementTypeId;
  });
  const domHand = handOptions.find((hand: { id: string }) => {
    return hand.id === analysis.domHand;
  });
  const determineDateCriteria = (segmentCriteria: ApiSegmentCriteria) => {
    if (segmentCriteria.sessionDate) {
      if (segmentCriteria.sessionDate[0] === segmentCriteria.sessionDate[1]) {
        return {
          value: format(
            parse(segmentCriteria.sessionDate[0], "yyyy-MM-dd", new Date()),
            "M/d/yyyy",
          ),
          label: "",
        };
      } else {
        return {
          value: `${format(
            parse(segmentCriteria.sessionDate[0], "yyyy-MM-dd", new Date()),
            "M/d/yyyy",
          )} - ${format(
            parse(segmentCriteria.sessionDate[1], "yyyy-MM-dd", new Date()),
            "M/d/yyyy",
          )}`,
          label: "",
        };
      }
    } else {
      return {
        value: "",
        label: "",
      };
    }
  };
  const determineDateRange = (segmentCriteria: ApiSegmentCriteria) => {
    if (segmentCriteria.sessionDate) {
      if (segmentCriteria.sessionDate[0] === segmentCriteria.sessionDate[1]) {
        return segmentCriteria.sessionDate[0];
      } else {
        return segmentCriteria.sessionDate;
      }
    } else {
      return "";
    }
  };
  const customAnalysis = {
    ...analysis,
    movementType,
    domHand,
    primarySegment: {
      criteria: primarySegment.criteria,
      dateCriteria: determineDateCriteria(primarySegment.criteria),
      dateRange: determineDateRange(primarySegment.criteria),
      id: primarySegment.id,
    },
  };
  if (populationSegment) {
    Object.assign(customAnalysis, {
      populationSegment: {
        criteria: populationSegment.criteria,
        dateCriteria: determineDateCriteria(populationSegment.criteria),
        dateRange: determineDateRange(populationSegment.criteria),
        id: populationSegment.id,
      },
    });
  } else if (comparisonSegment) {
    Object.assign(customAnalysis, {
      comparisonSegment: {
        criteria: comparisonSegment.criteria,
        dateCriteria: determineDateCriteria(comparisonSegment.criteria),
        dateRange: determineDateRange(comparisonSegment.criteria),
        id: comparisonSegment.id,
      },
    });
  }
  return customAnalysis;
};
