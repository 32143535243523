import { camelCaseResponse } from "../shared/Functions";
import {
  ApiUploadData,
  GenericApiResponse,
  UploadSessionFormValues,
} from "../types";
import {
  FilePartInfo,
  PatchSessionUploadInfo,
  PostSessionUploadResponse,
} from "../types/apiTypes";
import { emptyApi } from "./emptyApi";

export const uploadExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    postSessionUpload: builder.mutation<
      PostSessionUploadResponse,
      UploadSessionFormValues
    >({
      query: (uploadSessionFormValues) => ({
        url: "/upload_videos",
        method: "POST",
        body: processUploadSessionFormValues(uploadSessionFormValues),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
    }),
    patchSessionUpload: builder.mutation<
      GenericApiResponse,
      PatchSessionUploadInfo
    >({
      query: (args) => ({
        url: "/upload_videos",
        method: "PATCH",
        body: processFinalUpload(
          args["fileId"],
          args["uploadSessionId"],
          args["uploadSuccessful"],
          args["filePartInfo"],
        ),
      }),
      transformResponse: (response: any) => camelCaseResponse(response),
      invalidatesTags: ["Sessions", "Org"],
    }),
  }),
});

export const { usePostSessionUploadMutation, usePatchSessionUploadMutation } =
  uploadExtendedApi;

function processUploadSessionFormValues(
  uploadSessionFormValues: UploadSessionFormValues,
): Record<string, unknown> {
  const {
    selectedPlayer,
    sessionDate,
    sessionType,
    movementType,
    frameRate,
    files,
    sessionId,
  } = uploadSessionFormValues;
  const uploadData: ApiUploadData = {
    files: [{}],
    movement_type_id: parseInt(movementType.value),
    player_id: selectedPlayer.value,
    session_date: sessionDate,
    session_type_id: parseInt(sessionType.value),
    movement_tag_ids: [],
  };
  uploadData.files = files.map((file) => {
    return {
      file_id: file.fileId,
      raw_file_name: file.rawFileName,
      file_size_bytes: file.fileSizeBytes,
      frame_rate: parseInt(frameRate),
      number_of_chunks: file.numberOfChunks,
    };
  });
  if (sessionId !== "") {
    uploadData["session_id"] = sessionId;
  }
  return uploadData;
}

function processFinalUpload(
  fileId: number,
  uploadSessionId: number,
  uploadSuccessful: boolean,
  filePartInfo?: FilePartInfo,
): Record<string, unknown> {
  return {
    upload_session_id: uploadSessionId,
    affected_file_id: fileId,
    file_part_info: filePartInfo ? filePartInfo : [],
    upload_successful: uploadSuccessful,
  };
}
