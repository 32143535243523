import { createSlice } from "@reduxjs/toolkit";

import { CustomAnalysisFormValues } from "../../types";

export const initialState: CustomAnalysisFormValues = {
  name: "",
  hand: { value: "", label: "", id: "" },
  movementType: { value: "", label: "", id: 0 },
  primarySegment: {
    dateCriteria: { value: "", label: "" },
    dateRange: [],
    individualDates: [],
    criteria: [],
  },
  populationSegment: {
    dateCriteria: { value: "", label: "" },
    dateRange: [],
    individualDates: [],
    criteria: [],
  },
  comparisonSegment: {
    dateCriteria: { value: "", label: "" },
    dateRange: [],
    individualDates: [],
    criteria: [],
  },
};

export const customAnalysisFormSlice = createSlice({
  name: "customAnalysisForm",
  initialState,
  reducers: {
    storeCustomAnalysisFormState: (state, action) => {
      state.name = action.payload.name;
      state.hand = action.payload.hand;
      state.movementType = action.payload.movementType;
      state.primarySegment = action.payload.primarySegment;
      state.populationSegment = action.payload.populationSegment;
      state.comparisonSegment = action.payload.comparisonSegment;
    },
    updateSegmentCriteria: (state, action) => {
      if (action.payload.segmentType === "primary") {
        state.primarySegment.criteria[action.payload.index] = {
          ...state.primarySegment.criteria[action.payload.index],
          [action.payload.key]: action.payload.value,
        };
      } else if (action.payload.segmentType === "population") {
        state.populationSegment.criteria[action.payload.index] = {
          ...state.populationSegment.criteria[action.payload.index],
          [action.payload.key]: action.payload.value,
        };
      } else if (action.payload.segmentType === "comparison") {
        state.comparisonSegment.criteria[action.payload.index] = {
          ...state.comparisonSegment.criteria[action.payload.index],
          [action.payload.key]: action.payload.value,
        };
      }
    },
    clearCustomAnalysisFormState: (state) => {
      state = initialState;
      return state;
    },
  },
});
