import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TrashIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

import { useAppContext } from "../../../context";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import { FileFormValues } from "../../../types";

type SortableItemProps = {
  file: FileFormValues;
};

const SortableItem: React.FC<SortableItemProps> = ({ file }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: file.fileId });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // App context
  const { uploadFiles, setUploadFiles } = useAppContext();

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  // RTK Slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  const handleCancelClick = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const copiedFiles = [...uploadSessionFormValues.files];
    const copiedUploadFiles = [...uploadFiles];
    copiedFiles.splice(index, 1);
    copiedUploadFiles.splice(index, 1);
    setUploadFiles(copiedUploadFiles);
    dispatch(
      storeUploadSessionFormState({
        ...uploadSessionFormValues,
        files: copiedFiles,
      }),
    );
  };

  return (
    <div
      key={file.fileId}
      className="h-12 border border-gray-300 rounded-md px-4 flex items-center"
      data-testid={`file-container-${file.fileId}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex w-3/4">
          <div className="flex items-center">
            <VideoCameraIcon
              className="h-5 w-5 text-gray-400 stroke-2"
              data-testid="video-camera-icon"
            />
          </div>
          <p
            className="text-gray-700 text-sm sm:text-base truncate pl-4"
            data-testid={`file-${file.fileId}-name-text`}
          >
            {file.rawFileName}
          </p>
        </div>
        <div
          className="flex justify-end w-1/3 sm:w-auto"
          data-testid="delete-file-button-container"
        >
          <button
            className="text-gray-400 text-sm sm:text-base justify-end sm:pl-2 pointer-events-auto"
            data-testid="delete-file-button"
            onClick={(event) =>
              handleCancelClick(
                uploadSessionFormValues.files.indexOf(file),
                event,
              )
            }
          >
            <TrashIcon className="h-5 w-5 stroke-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const SortableFileList: React.FC = () => {
  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  // State
  const [sortableFiles, setSortableFiles] = useState<FileFormValues[]>(
    uploadSessionFormValues.files,
  );
  const [updateFileList, setUpdateFileList] = useState(false);

  // RTK Slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  const movementType = uploadSessionFormValues.movementType.label;
  const frameRate = uploadSessionFormValues.frameRate;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    } else if (active.id !== over?.id) {
      setSortableFiles((prevFiles) => {
        const oldIndex = prevFiles.findIndex(
          (file) => file.fileId === active.id,
        );
        const newIndex = prevFiles.findIndex(
          (file) => file.fileId === over?.id,
        );
        return arrayMove(prevFiles, oldIndex, newIndex);
      });
      setUpdateFileList(true);
    }
  };

  useEffect(() => {
    if (uploadSessionFormValues.files.length > 0) {
      setSortableFiles(uploadSessionFormValues.files);
    }
  }, [uploadSessionFormValues]);

  useEffect(() => {
    if (updateFileList) {
      dispatch(
        storeUploadSessionFormState({
          ...uploadSessionFormValues,
          files: sortableFiles,
        }),
      );
      setUpdateFileList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFileList, sortableFiles]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={sortableFiles.map((file) => file.fileId)}
        strategy={verticalListSortingStrategy}
      >
        <div
          className="flex flex-col gap-2 mb-8"
          data-testid="sortable-file-list"
        >
          <p className="text-gray-900 font-medium text-sm sm:text-base mb-1">
            {`${movementType} - ${frameRate} FPS`}
          </p>
          {sortableFiles.map((file, index) => (
            <SortableItem key={index} file={file} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
