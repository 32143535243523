import React from "react";

import { joinClassNames } from "../../shared/Functions";

export const StepCircle = ({
  active,
  number,
}: {
  active: boolean;
  number: number;
}) => {
  return (
    <div className="flex items-start py-1 text-sm font-medium">
      <span className="shrink-0">
        <span
          className={joinClassNames(
            active ? "border-[#FB6A07]" : "border-[#9ca3af]",
            "flex size-10 items-center justify-center rounded-full border-2",
          )}
          data-testid={
            active
              ? `step-${number}-active-icon`
              : `step-${number}-inactive-icon`
          }
        >
          <span className={active ? "text-[#FB6A07]" : "text-[#9ca3af]"}>
            {number}
          </span>
        </span>
      </span>
    </div>
  );
};
