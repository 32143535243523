import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { BlankButton, PrimaryButton } from "..";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { customAnalysisFormSlice } from "../../redux/slices/customAnalysisFormSlice";
import { playerGroupSlice } from "../../redux/slices/playerGroupSlice";
import { toTitleCase } from "../../shared/Functions";

export const NameDialog: React.FC = (): JSX.Element => {
  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeCustomAnalysisFormState } = customAnalysisFormSlice.actions;
  const { storePlayerGroup } = playerGroupSlice.actions;

  // Redux calls
  const customAnalysisFormState = useAppSelector(
    (state) => state.customAnalysisForm,
  );
  const playerGroupState = useAppSelector((state) => state.playerGroup);

  // State and AppContext
  const { dialogState, setDialogState } = useAppContext();
  const [inputValue, setInputValue] = useState("");
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [analysisName, setAnalysisName] = useState(
    customAnalysisFormState.name,
  );
  const [groupName, setGroupName] = useState(playerGroupState.groupName);

  const cancelButtonRef = useRef(null);

  const { push } = useHistory();

  const closeDialog = () => {
    setDialogState({
      shouldOpen: false,
      type: "",
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dialogState.type === "analysis") {
      setAnalysisName(event.target.value);
    } else if (dialogState.type === "group") {
      setGroupName(event.target.value);
    }
  };

  const handleSave = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (dialogState.type === "analysis") {
      closeDialog();
      dispatch(
        storeCustomAnalysisFormState({
          ...customAnalysisFormState,
          name: analysisName,
        }),
      );
      if (
        location.pathname === "/reports/custom" ||
        location.pathname === "/"
      ) {
        push("/request-analysis");
      }
    } else if (dialogState.type === "group") {
      closeDialog();
      dispatch(
        storePlayerGroup({
          ...playerGroupState,
          modalOpenState: true,
          groupName,
        }),
      );
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (dialogState && event.key === "Enter") {
      event.preventDefault();
      handleSave(event);
    }
  };

  useEffect(() => {
    if (dialogState.type === "analysis") {
      setInputValue(analysisName);
    } else if (dialogState.type === "group") {
      setInputValue(groupName);
    }
  }, [dialogState.type, analysisName, groupName]);

  useEffect(() => {
    if (dialogState.type === "analysis" && analysisName === "") {
      setDisableSaveBtn(true);
    } else if (dialogState.type === "group" && groupName === "") {
      setDisableSaveBtn(true);
    } else {
      setDisableSaveBtn(false);
    }
  }, [analysisName, dialogState.type, groupName]);

  return (
    <Transition.Root show={dialogState.shouldOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999999]"
        initialFocus={cancelButtonRef}
        onClose={closeDialog}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <form
          className="fixed inset-0 z-10 overflow-y-auto"
          onSubmit={(event) => handleSave(event)}
          data-testid={`name-${dialogState.type}-dialog`}
        >
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={closeDialog}
                      data-testid={`name-${dialogState.type}-x-button`}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:flex-col sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl font-medium leading-6 text-gray-900"
                        data-testid={`name-${dialogState.type}-dialog-title`}
                      >
                        {`Name ${toTitleCase(dialogState.type)}`}
                      </Dialog.Title>
                    </div>
                    <div className="mt-4">
                      <p
                        className="text-sm text-gray-500"
                        data-testid={`name-${dialogState.type}-helper-text`}
                      >
                        {`Give this ${dialogState.type} a memorable name. You can change this later.`}
                      </p>
                    </div>
                    <label
                      htmlFor={`${dialogState.type}-name-input`}
                      className="sr-only"
                      id={`screen-reader-name-${dialogState.type}-label`}
                    >
                      {`Name ${toTitleCase(dialogState.type)}`}
                    </label>
                    <input
                      id={`${dialogState.type}-name-input`}
                      name={`${dialogState.type}-name-input`}
                      className="block w-full h-full my-8 pr-3 py-2 border border-gray-300 text-gray-900 placeholder-gray-400 bg-white rounded-lg focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder={`Name ${toTitleCase(dialogState.type)}...`}
                      value={inputValue}
                      onChange={handleChange}
                      type="text"
                      data-testid={`${dialogState.type}-name-input`}
                      onKeyDown={(event) => handleEnterKey(event)}
                    />
                  </div>
                  {dialogState.type === "analysis" &&
                    location.pathname === "/" && (
                      <div
                        data-testid="advanced-custom-analysis-text"
                        className="flex justify-end"
                      >
                        <p className="text-xs text-gray-500">
                          <span>
                            Looking for more advanced Custom Analysis
                            functionality? Click{" "}
                          </span>
                          <a
                            href="https://colab.research.google.com/github/RebootMotion/reboot-toolkit/blob/main/examples_api/RebootMotionCustomReport.ipynb"
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-500 hover:text-blue-700"
                            data-testid="advanced-custom-analysis-link"
                          >
                            here
                          </a>
                          <span>.</span>
                        </p>
                      </div>
                    )}
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:justify-end sm:px-6">
                  <BlankButton
                    className="mr-4 justify-center"
                    onClick={closeDialog}
                    data-testid={`name-${dialogState.type}-cancel-button`}
                  >
                    Cancel
                  </BlankButton>
                  <PrimaryButton
                    type="submit"
                    disabled={disableSaveBtn}
                    data-testid={`name-${dialogState.type}-save-button`}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};
