import {
  CalendarIcon,
  DocumentChartBarIcon,
} from "@heroicons/react/24/outline";
import { format, getYear, subDays } from "date-fns";
import range from "lodash/range";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";

import {
  AnalysisButton,
  ErrorToast,
  LoadingSpinner,
  StepType,
} from "../components";
import {
  SelectDetailsStep,
  SelectMovementTypeStep,
  SelectPlayerStep,
  UploadFilesStep,
} from "../features";
import { useAppSelector } from "../redux/hooks";
import {
  Org,
  Player,
  PlayerFormValues,
  ReportType,
  Session,
  UploadSessionFormValues,
} from "../types";
import { FormTypeEnum } from "../types/formTypes";

export type UploadSessionStepListProps = {
  /** List of players, used in the first screen for the player select */
  playerList: Player[];
  /** The form values for the session upload process */
  sessionFormValues: UploadSessionFormValues;
  /** Organization data used in the upload session form process */
  orgData: Org;
};

export type SelectSessionDetailsProps = {
  /** Organization ID for the currently logged in user */
  orgId: string;
};

export const possibleMovementTypes: { [key: string]: string } = {
  baseballPitching: "Pitches",
  baseballHitting: "Swings",
  basketballShooting: "Shots",
  verticalJumping: "Jumps",
  squatting: "Squats",
  lunging: "Lunges",
  legRaising: "Leg Raises",
};

export const CustomCalendarIcon = (): JSX.Element => {
  return (
    <CalendarIcon
      className="h-6 pr-2 text-gray-700 stroke-2"
      data-testid="calendar-icon"
    />
  );
};

// Sourced from this Stack Overflow thread: https://stackoverflow.com/questions/3009993/what-would-be-regex-for-matching-foreign-characters
export const regexForForeignLettersAndSpecialChars = /^[\p{L}'\-\s\.]*$/u;

export const currentYear = format(new Date(), "yyyy");
export const today = format(new Date(), "M/d/yyyy");
export const years = range(1990, getYear(new Date()) + 1, 1);

export const dateRangeOptions = [
  { value: today, label: "Today" },
  { value: format(subDays(new Date(), 1), "M/d/yyyy"), label: "Yesterday" },
  {
    value: `${format(subDays(new Date(), 7), "M/d/yyyy")} - ${today}`,
    label: "Last 7 Days",
  },
  {
    value: `${format(subDays(new Date(), 14), "M/d/yyyy")} - ${today}`,
    label: "Last 14 Days",
  },
  {
    value: `${format(subDays(new Date(), 30), "M/d/yyyy")} - ${today}`,
    label: "Last 30 Days",
  },
  {
    value: `${format(subDays(new Date(), 60), "M/d/yyyy")} - ${today}`,
    label: "Last 60 Days",
  },
  {
    value: `${format(subDays(new Date(), 365), "M/d/yyyy")} - ${today}`,
    label: "Last Year",
  },
  { value: "Custom Range", label: "Custom Range" },
  { value: "Individual Dates", label: "Individual Dates" },
];

export const playerListLimit = 2000;
export const sessionListLimit = 1000;

export const emptyPlayer: Player = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  throws: "",
  hits: "",
  height: {
    feet: "",
    inches: "",
  },
  weight: "",
  orgPlayerId: "",
  officialLeagueId: "",
};

export const emptyPlayerFormValues: PlayerFormValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: null,
  throws: { value: "", label: "" },
  hits: { value: "", label: "" },
  heightFeet: "",
  heightInches: "",
  weight: "",
  orgPlayerId: "",
  officialLeagueId: "",
};

export const emptyProgressModalState = {
  shouldOpen: false,
  title: "",
};

export const uploadSessionStepList = ({
  playerList,
  sessionFormValues,
  orgData,
}: UploadSessionStepListProps): StepType[] => {
  const validateFrameRate = (frameRate: string): boolean => {
    if (frameRate === "") {
      return true;
    }

    const frameRateNumber = parseInt(frameRate, 10);
    return frameRateNumber >= 28 && frameRateNumber <= 1002;
  };

  return [
    {
      title: isMobile ? "Player" : "Select Player",
      display: <SelectPlayerStep players={playerList ? playerList : []} />,
      disableNext: sessionFormValues.selectedPlayer.value === "",
      formType: FormTypeEnum.UploadSessionForm,
    },
    {
      title: "Details",
      display: <SelectDetailsStep orgData={orgData} />,
      disableNext:
        sessionFormValues.sessionType.value === "" ||
        sessionFormValues.sessionVenue.value === "",
      formType: FormTypeEnum.UploadSessionForm,
    },
    {
      title: isMobile ? "Movement" : "Select Movement Type",
      display: <SelectMovementTypeStep orgData={orgData} />,
      disableNext:
        sessionFormValues.movementType.value === "" ||
        sessionFormValues.frameRate === "" ||
        !validateFrameRate(sessionFormValues.frameRate),
      formType: FormTypeEnum.UploadSessionForm,
    },
    {
      title: "Upload",
      display: <UploadFilesStep />,
      disableNext: sessionFormValues.files.length === 0,
      formType: FormTypeEnum.UploadSessionForm,
    },
  ];
};

export const errorToast = (message: string, id?: string): string =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: id ? id : "",
  });

export const customSelectStyles = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  control: (provided: any) => ({
    ...provided,
    fontSize: "0.875rem",
    borderColor: "#DBDEE2",
    borderRadius: "6px",
  }),
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  option: (styles: any, { isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? "#F9FAFB"
        : isFocused
          ? "#F9FAFB"
          : undefined,
      color: isSelected ? "#FB6A07" : undefined,
      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#F9FAFB" : undefined,
      },
    };
  },
};

export const AnalysisCell = ({
  element,
  fetchingReports,
  session,
  openReportsModal,
}: {
  element: any;
  fetchingReports: boolean;
  session: Session | undefined;
  openReportsModal: (player: any, session: any) => void;
}): JSX.Element => {
  const urls = element.row.original.reports
    ? element.row.original.reports.map((report: ReportType) => report.reportUrl)
    : [];
  return (
    <div data-testid="analysis-cell">
      {fetchingReports && !urls.length ? (
        <div data-testid="upload-spinner-container">
          <LoadingSpinner />
        </div>
      ) : urls.length ? (
        urls.length > 1 ? (
          <AnalysisButton
            data-testid="analysis-button"
            onClick={() => openReportsModal(element.row.original, session)}
          >
            <span className="flex">
              <DocumentChartBarIcon className="w-5 mr-2 stroke-2" /> (
              {urls.length})
            </span>
          </AnalysisButton>
        ) : (
          <a
            href={urls[0]}
            target="_blank"
            rel="noreferrer"
            data-testid="single-analysis-link"
          >
            <AnalysisButton data-testid="analysis-button">
              <DocumentChartBarIcon className="w-5 stroke-2" />
            </AnalysisButton>
          </a>
        )
      ) : (
        <AnalysisButton disabled data-testid="disabled-analysis-button">
          <DocumentChartBarIcon className="w-5 stroke-2" />
        </AnalysisButton>
      )}
    </div>
  );
};

export const useSessionsTableFilters = () => {
  return [
    {
      type: "date",
      placeholder: "Session Date",
      filterOptions: dateRangeOptions,
      columnId: "sessionDate",
    },
    {
      type: "dropdown",
      placeholder: "Session Type",
      filterOptions: useSessionTypeOptions(),
      columnId: "typeAndVenue",
    },
  ];
};

export const useReportsTableFilters = () => {
  const reportsTableFilters = [
    {
      type: "date",
      placeholder: "Date Range",
      filterOptions: dateRangeOptions,
      columnId: "createdAt",
    },
    {
      type: "dropdown",
      placeholder: "Movement Type",
      filterOptions: useMovementTypeOptions(),
      columnId: "movementType",
    },
  ];
  return reportsTableFilters;
};

export const useMovementTypeOptions = () => {
  const orgData = useAppSelector((state) => state.org);
  const movementTypes = orgData.details.movementTypes.map((movement) => {
    return { value: `${movement.name}`, label: `${movement.name}` };
  });

  return [...movementTypes, { value: "", label: "All Movements" }].sort(
    (a, b) => a.label.localeCompare(b.label),
  );
};

export const useSessionTypeOptions = () => {
  const orgData = useAppSelector((state) => state.org);

  const sessionTypes = orgData.details.sessionTypes
    ? orgData.details.sessionTypes.map((sessionType) => {
        const slug = sessionType.slug.includes("-")
          ? sessionType.slug.replace(/-/g, " ")
          : sessionType.slug;
        return { value: `${slug}`, label: `${sessionType.name}` };
      })
    : [];

  return [...sessionTypes, { value: "", label: "All Types" }].sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};
