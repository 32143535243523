import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";

import { SecondaryButton } from "../../../components";
import { useAppSelector } from "../../../redux/hooks";
import { ApiPlayerGroupSegmentData } from "../../../redux/slices/customAnalysisSlice";

type SegmentDetailsProps = {
  segmentType: string;
  setPrimarySegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  setPopulationSegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  setComparisonSegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPrimarySegment?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPopulationSegment?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditComparisonSegment?: React.Dispatch<React.SetStateAction<boolean>>;
};

const emptySegmentData = {
  groupdId: 0,
  repMovementId: "",
  analysisSegmentId: null,
  numSessions: 0,
  numMovements: 0,
  numPlayers: 0,
  criteria: {},
  orgId: "",
  id: null,
};

export const SegmentDetails: React.FC<SegmentDetailsProps> = ({
  segmentType,
  setPrimarySegmentDefined,
  setPopulationSegmentDefined,
  setComparisonSegmentDefined,
  setEditPrimarySegment,
  setEditPopulationSegment,
  setEditComparisonSegment,
}: SegmentDetailsProps): JSX.Element => {
  const [currentSegmentData, setCurrentSegmentData] =
    useState<ApiPlayerGroupSegmentData>(emptySegmentData);

  // Redux calls
  const customAnalysisState = useAppSelector((state) => state.customAnalysis);
  const playerList = useAppSelector((state) => state.players.playerList);
  const mocapTypes = useAppSelector((state) => state.org.details.mocapTypes);

  const { primarySegmentData, populationSegmentData, comparisonSegmentData } =
    customAnalysisState;

  useEffect(() => {
    if (segmentType === "primary") {
      setCurrentSegmentData(primarySegmentData);
    } else if (segmentType === "population") {
      setCurrentSegmentData(populationSegmentData);
    } else if (segmentType === "comparison") {
      setCurrentSegmentData(comparisonSegmentData);
    }
  }, [
    comparisonSegmentData,
    populationSegmentData,
    primarySegmentData,
    segmentType,
  ]);

  const determineDateRange = (segmentData: ApiPlayerGroupSegmentData) => {
    const dates = segmentData?.criteria.sessionDate;
    if (dates && dates.length > 1) {
      const startDate = format(
        parse(dates[0], "yyyy-MM-dd", new Date()),
        "M/d/yyyy",
      );
      const endDate = format(
        parse(dates[1], "yyyy-MM-dd", new Date()),
        "M/d/yyyy",
      );
      return `${startDate} - ${endDate}`;
    } else if (dates && dates.length === 1) {
      const startDate = format(
        parse(dates[0], "yyyy-MM-dd", new Date()),
        "M/d/yyyy",
      );
      return startDate;
    }
  };

  const determineSessionDates = (segmentData: ApiPlayerGroupSegmentData) => {
    const dates = segmentData?.criteria.sessionDateIn;
    if (dates) {
      const formattedDates = dates
        .map((date: string) => {
          return format(parse(date, "yyyy-MM-dd", new Date()), "M/d/yyyy");
        })
        .join(", ");
      return formattedDates;
    }
  };

  const determinePlayerNames = (segmentData: ApiPlayerGroupSegmentData) => {
    const playerIds = segmentData?.criteria.orgPlayerIds;
    const playerNameArray = playerIds
      ?.map((id) => {
        const foundPlayer = playerList.find((player) => {
          return player.orgPlayerId === id;
        });
        return `${foundPlayer?.firstName} ${foundPlayer?.lastName}`;
      })
      .join(", ");
    return playerNameArray;
  };

  const determineMocapType = (segmentData: ApiPlayerGroupSegmentData) => {
    const mocapTypeId = segmentData?.criteria.mocapTypeId;
    const mocapType = mocapTypes.find((type) => {
      return type.id === mocapTypeId;
    });
    return mocapType?.name;
  };

  const editSegment = (segment: string) => {
    if (segment === "primary") {
      setPrimarySegmentDefined?.(false);
      setEditPrimarySegment?.(true);
    } else if (segment === "population") {
      setPopulationSegmentDefined?.(false);
      setEditPopulationSegment?.(true);
    } else if (segment === "comparison") {
      setComparisonSegmentDefined?.(false);
      setEditComparisonSegment?.(true);
    }
  };

  const processPitchCharValues = (
    pitchCharValues: string[],
    pitchChar: string,
  ) => {
    const values = pitchCharValues.map((item) => {
      const unitOfMeasure =
        item.includes("<=") || item.includes(">=")
          ? item.slice(2)
          : item.slice(1);
      const comparisonOperator =
        item.includes("<=") || item.includes(">=")
          ? item.slice(0, 2)
          : item.slice(0, 1);
      if (pitchChar === "releaseSpeed") {
        return `${comparisonOperator} ${unitOfMeasure} mph`;
      } else {
        return `${comparisonOperator} ${Number(unitOfMeasure) * 12} inches`;
      }
    });
    return values.join(" and ");
  };

  return (
    <div data-testid={`${segmentType}-segment-details-container`}>
      <div
        className="mt-4 py-4 p-8 min-h-[10rem] overflow-scroll"
        data-testid={`${segmentType}-segment-review-criteria-container`}
      >
        <h3 className="text-primary-800 text-base mb-2">Segment Criteria</h3>
        <div
          className="flex flex-col text-base space-y-2"
          data-testid={`${segmentType}-segment-criteria-details`}
        >
          {currentSegmentData.criteria.sessionDate && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-date`}
            >{`Date Range: ${determineDateRange(currentSegmentData)}`}</p>
          )}
          {currentSegmentData.criteria.sessionDateIn && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-session-dates`}
            >{`Session Dates: ${determineSessionDates(currentSegmentData)}`}</p>
          )}
          {currentSegmentData.criteria.horizontalBreak && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-horiz-break`}
            >{`Horizontal Break: ${processPitchCharValues(
              currentSegmentData.criteria.horizontalBreak,
              "horizontalBreak",
            )}`}</p>
          )}
          {currentSegmentData.criteria.inducedVerticalBreak && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-ind-vert-break`}
            >{`Induced Vertical Break: ${processPitchCharValues(
              currentSegmentData.criteria.inducedVerticalBreak,
              "inducedVerticalBreak",
            )}`}</p>
          )}
          {currentSegmentData.criteria.mocapTypeId && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-mocap-type`}
            >{`Mocap Type: ${determineMocapType(currentSegmentData)}`}</p>
          )}
          {currentSegmentData.criteria.pitchTypes && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-pitch-type`}
            >{`Pitch Type: ${currentSegmentData.criteria.pitchTypes.join(
              ", ",
            )}`}</p>
          )}
          {currentSegmentData.criteria.mocapPitchTypes && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-mocap-pitch-type`}
            >{`Mocap Pitch Type: ${currentSegmentData.criteria.mocapPitchTypes.join(
              ", ",
            )}`}</p>
          )}
          {currentSegmentData.criteria.orgPlayerIds && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-player`}
            >{`Player Name: ${determinePlayerNames(currentSegmentData)}`}</p>
          )}
          {currentSegmentData.criteria.relSpeedMph && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-rel-speed`}
            >{`Release Speed: ${processPitchCharValues(
              currentSegmentData.criteria.relSpeedMph,
              "releaseSpeed",
            )}`}</p>
          )}
          {currentSegmentData.criteria.verticalBreak && (
            <p
              className="text-gray-500"
              data-testid={`${segmentType}-segment-vert-break`}
            >{`Vertical Break: ${processPitchCharValues(
              currentSegmentData.criteria.verticalBreak,
              "verticalBreak",
            )}`}</p>
          )}
        </div>
      </div>
      <div
        className="border-t border-[#E5E7EB]"
        data-testid={`${segmentType}-segment-footer`}
      >
        <div
          className="pl-8 py-4"
          data-testid={`${segmentType}-segment-buttons-container`}
        >
          <SecondaryButton
            data-testid={`${segmentType}-segment-edit-button`}
            onClick={() => editSegment(segmentType)}
            type="button"
          >
            Edit
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};
