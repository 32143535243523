import { add, addDays, format } from "date-fns";
import { parseISO } from "date-fns/esm";
import { Formik } from "formik";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

import { CustomDropdownWithChevron } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import {
  CustomCalendarIcon,
  customSelectStyles,
} from "../../../shared/Constants";
import { Org } from "../../../types";

type SelectDetailsStepProps = {
  orgData: Org;
};

export const SelectDetailsStep: React.FC<SelectDetailsStepProps> = ({
  orgData,
}) => {
  const [, setIsMenuOpen] = useState(false);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const sessionTypeOptions = orgData.details.sessionTypes
    ? orgData.details.sessionTypes.map((session) => {
        return { value: `${session.id}`, label: `${session.name}` };
      })
    : [];

  const sessionVenueOptions =
    !orgData.details.sessionVenues || orgData.details.sessionVenues.length === 0
      ? [{ value: "0", label: "Default Venue" }]
      : orgData.details.sessionVenues.map((venue) => {
          return { value: `${venue.id}`, label: `${venue.slug}` };
        });

  return (
    <Formik
      initialValues={{
        sessionDate: uploadSessionFormValues.sessionDate,
        sessionType: uploadSessionFormValues.sessionType,
        sessionVenue: uploadSessionFormValues.sessionVenue,
      }}
      validationSchema={Yup.object({
        sessionDate: Yup.date().max(add(new Date(), { days: 1 })),
        sessionType: Yup.object({
          value: Yup.string(),
          label: Yup.string(),
        }),
        sessionVenue: Yup.object({
          value: Yup.string(),
          label: Yup.string(),
        }),
      })}
      onSubmit={() => {
        false;
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <form
          className="flex flex-col sm:h-96 w-full items-center mt-4 pb-8 sm:mt-0"
          onSubmit={handleSubmit}
        >
          <fieldset className="w-3/4 sm:pt-10 flex flex-col sm:flex-row justify-center">
            <div
              className="flex flex-col sm:w-5/12 space-y-8"
              data-testid="session-details-fields-container"
            >
              <div data-testid="session-date-container">
                <div className="flex">
                  <label
                    id="session-date-label"
                    htmlFor="session-date-date-picker"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Date
                  </label>
                  <span
                    className="text-red-500 text-sm"
                    data-testid="session-date-required-asterisk"
                  >
                    *
                  </span>
                </div>
                <div
                  className="flex items-center justify-between w-full h-9 border border-gray-200 rounded-md focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  data-testid="date-picker-container"
                >
                  <DatePicker
                    id="session-date-picker"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={"Select Session Date"}
                    selected={
                      uploadSessionFormValues.sessionDate === ""
                        ? null
                        : parseISO(uploadSessionFormValues.sessionDate)
                    }
                    dateFormat="MMMM d, yyyy"
                    name="sessionDate"
                    todayButton="Today"
                    maxDate={addDays(new Date(), 0)}
                    className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm cursor-default"
                    onChange={(date) => {
                      if (date instanceof Date && date.getTime()) {
                        dispatch(
                          storeUploadSessionFormState({
                            ...uploadSessionFormValues,
                            sessionDate: format(
                              parseISO(date.toISOString()),
                              "yyyy-MM-dd",
                            ),
                          }),
                        );
                        setFieldValue("sessionDate", date);
                      }
                    }}
                  />
                  <CustomCalendarIcon />
                </div>
              </div>
              <div data-testid="session-type-container">
                <div className="flex">
                  <label
                    id="session-type-label"
                    htmlFor="session-type-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Type
                  </label>
                  <span
                    className="text-red-500 text-sm"
                    data-testid="session-type-required-asterisk"
                  >
                    *
                  </span>
                </div>
                <Select
                  classNamePrefix="session-type-select"
                  inputId="session-type-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={sessionTypeOptions}
                  value={
                    uploadSessionFormValues.sessionType.value === ""
                      ? null
                      : uploadSessionFormValues.sessionType
                  }
                  placeholder="Select Session Type"
                  onChange={(sessionType) => {
                    if (sessionType) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          sessionType,
                        }),
                      );
                    }
                    setFieldValue("sessionType", sessionType);
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                  maxMenuHeight={isMobile ? 180 : 200}
                />
              </div>
              <div data-testid="session-venue-container">
                <div className="flex">
                  <label
                    id="session-venue-label"
                    htmlFor="session-venue-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Venue
                  </label>
                  <span
                    className="text-red-500 text-sm"
                    data-testid="venue-required-asterisk"
                  >
                    *
                  </span>
                </div>
                <Select
                  classNamePrefix="session-venue-select"
                  inputId="session-venue-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={sessionVenueOptions}
                  value={
                    uploadSessionFormValues.sessionVenue.value === ""
                      ? null
                      : uploadSessionFormValues.sessionVenue
                  }
                  placeholder="Select Venue"
                  onChange={(sessionVenue) => {
                    if (sessionVenue) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          sessionVenue,
                        }),
                      );
                      setFieldValue("sessionVenue", sessionVenue);
                    }
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
