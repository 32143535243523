import { ArrowDownTrayIcon, EyeIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  AggregateGroupHeaderSkeleton,
  ColumnOneTableCellSkeleton,
  IconButton,
  Table,
  TableCellSkeleton,
} from "../../../components";
import { columnHelper } from "../../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../../context";
import { useGetAggregateGroupByIdQuery } from "../../../services";
import { downloadS3Files } from "../../../shared/Functions";

type AggregateGroupRouteParams = {
  reportId: string;
};

export const AggregateReportGroup: React.FC = (): JSX.Element => {
  const routeParams = useParams<AggregateGroupRouteParams>();
  const reportId = routeParams.reportId;

  // State and AppContext
  const { setDisplayBack } = useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("sessionPageSize") || "10",
  );

  // RTK Query
  const { data: aggregateGroup, isFetching: fetchingAggregateGroup } =
    useGetAggregateGroupByIdQuery(reportId);

  const tableData = useMemo(
    () =>
      fetchingAggregateGroup
        ? Array(10).fill({})
        : aggregateGroup
          ? aggregateGroup.aggregateAnalyses
          : [],
    [fetchingAggregateGroup, aggregateGroup],
  );

  const aggregateGroupColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        enableSorting: false,
        size: 600,
        maxSize: 800,
        cell: function nameCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="aggregate-report-name"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Created",
        sortingFn: "datetime",
        cell: function dateCell(element: any) {
          return (
            <div
              className="font-medium text-gray-900 cursor-default"
              data-testid="aggregate-report-date-created"
            >
              {format(new Date(element.getValue()), "M/d/yyyy")}
            </div>
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "",
        enableSorting: false,
        cell: function actionsCell(element: any) {
          const reportUrl = element.row.original.reportUrl;
          return (
            <div
              className="flex justify-end pr-8"
              data-testid="aggregate-report-actions-container"
            >
              <a
                href={reportUrl}
                target="_blank"
                rel="noreferrer"
                data-testid="view-aggregate-report-link"
                className="mr-4"
              >
                <IconButton>
                  <EyeIcon className="h-5 w-5 stroke-2" />
                </IconButton>
              </a>
              <IconButton
                onClick={() => downloadS3Files([reportUrl])}
                data-testid="download-aggregate-report-button"
              >
                <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
              </IconButton>
            </div>
          );
        },
      }),
    ],
    [],
  );

  const tableColumns = useMemo(
    () =>
      fetchingAggregateGroup
        ? aggregateGroupColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : aggregateGroupColumns,
    [fetchingAggregateGroup, aggregateGroupColumns],
  );

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
  }, [setDisplayBack]);

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      {fetchingAggregateGroup && <AggregateGroupHeaderSkeleton />}
      {aggregateGroup && (
        <div
          className="flex flex-col justify-start mt-8 max-w-7xl px-4"
          data-testid="aggregate-group-header"
        >
          <h2
            className="max-w-7xl px-4 pl-4 text-3xl leading-6 font-bold text-gray-900"
            data-testid="aggregate-group-date-range"
          >
            {aggregateGroup.dateRange}
          </h2>
          <p
            className="text-base mt-8 px-4 text-gray-400 font-bold"
            data-testid="aggregate-group-movement-type"
          >
            {aggregateGroup.movementType}
          </p>
        </div>
      )}
      <div
        className="block min-w-max mt-8"
        data-testid="aggregate-group-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="aggregate-group-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingAggregateGroup}
          customRowClassName="aggregate-group-row"
        />
      </div>
    </main>
  );
};
