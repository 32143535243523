import { format } from "date-fns";
import {
  camelCaseResponse,
  formatMovementType,
  providesList,
} from "../shared/Functions";
import { emptyApi } from "./emptyApi";

export const reportExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportsByOrgPlayerId: builder.query({
      query: (args) => {
        const { limit, orgPlayerId, offset, movementTypeSlugs } = args;
        return {
          url: `/reports`,
          params: {
            limit,
            offset,
            org_player_ids: orgPlayerId,
            movement_types: movementTypeSlugs,
          },
        };
      },
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getReportsBySessionId: builder.query({
      query: (args) => {
        const { sessionId, movementTypeSlugs } = args;
        return {
          url: `/reports`,
          params: {
            session_ids: sessionId,
            movement_types: movementTypeSlugs,
          },
        };
      },
      transformResponse: (response: any) => {
        return camelCaseResponse(response);
      },
      providesTags: (result, error, id) => [{ type: "Reports", id }],
    }),
    getFlaggedReports: builder.query<any, void>({
      query: () => "/flagged_analyses",
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: (results) => providesList(results, "FlaggedReports"),
    }),
    getAggregateReportGroups: builder.query<any, void>({
      query: () => "/aggregate_analyses_groups",
      transformResponse: (response: any) =>
        processAggregateData(camelCaseResponse(response)),
      providesTags: (results) => providesList(results, "AggregateReports"),
    }),
    getAggregateGroupById: builder.query({
      query: (id) => `/aggregate_analyses_groups/${id}`,
      transformResponse: (response: any) =>
        processAggregateData(camelCaseResponse(response)),
      providesTags: (result, error, id) => [{ type: "AggregateReports", id }],
    }),
  }),
});

export const {
  useGetReportsByOrgPlayerIdQuery,
  useGetReportsBySessionIdQuery,
  useGetFlaggedReportsQuery,
  useGetAggregateReportGroupsQuery,
  useGetAggregateGroupByIdQuery,
} = reportExtendedApi;

const processAggregateData = (response: any) => {
  const aggregateData = Array.isArray(response)
    ? response.map((report: any) => {
        return {
          ...report,
          dateRange: `${format(new Date(`${report.dateStart}T00:00:00`), "M/d/yyyy")} - ${format(
            new Date(`${report.dateEnd}T00:00:00`),
            "M/d/yyyy",
          )}`,
          movementType: formatMovementType(report.movementType),
        };
      })
    : {
        ...response,
        dateRange: `${format(new Date(`${response.dateStart}T00:00:00`), "M/d/yyyy")} - ${format(
          new Date(`${response.dateEnd}T00:00:00`),
          "M/d/yyyy",
        )}`,
        movementType: formatMovementType(response.movementType),
      };
  return aggregateData;
};
