import { format } from "date-fns";
import React, { ReactNode } from "react";

import { displayMovementCount } from "../../../shared/Functions";
import { Session } from "../../../types";

type MobileProcessingSessionsProps = {
  processingSessions: Session[];
};

export const MobileProcessingSessions: React.FC<
  MobileProcessingSessionsProps
> = ({ processingSessions }: MobileProcessingSessionsProps): JSX.Element => {
  return (
    <ul data-testid="mobile-sessions-processing-list">
      {processingSessions.map((session: Session, index) => {
        const sessionMovements = displayMovementCount(
          session.numberOfMovements,
          session.sessionType.slug,
        ).split(",");
        return (
          <li
            key={index}
            className="py-4 border-b border-gray-200 h-24 sm:px-4"
            data-testid={`mobile-session`}
          >
            <div className="flex justify-between mx-4 text-sm text-gray-500">
              <div>
                <p className="text-gray-900" data-testid="mobile-session-date">
                  {format(new Date(session.sessionDate), "M/d/yyyy")}
                </p>
                {sessionMovements[0] !== "" &&
                  sessionMovements.map((movement) => (
                    <p
                      className="flex justify-begin items-center"
                      data-testid={`mobile-session-movement-count-${index}`}
                      key={movement}
                    >
                      <span className="font-medium py-1">
                        {movement.split(":")[0]}:
                      </span>
                      <span className="text-gray-900 pl-1">
                        {movement.split(":")[1].toString()}
                      </span>
                    </p>
                  ))}
              </div>
              {session.statusComponent as ReactNode}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
