import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

import { CustomDropdownWithChevron } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import { customSelectStyles } from "../../../shared/Constants";
import { joinClassNames } from "../../../shared/Functions";
import { Org } from "../../../types";

type SelectMovementTypeStepProps = {
  orgData: Org;
};

export const SelectMovementTypeStep: React.FC<SelectMovementTypeStepProps> = ({
  orgData,
}) => {
  const [, setIsMenuOpen] = useState(false);
  const [fps, setFps] = useState<number | null>(null);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const standardMovementTypes = orgData.details.movementTypes
    .filter(
      ({ slug }) => slug.includes("baseball") || slug.includes("basketball"),
    )
    .map(({ id, name }) => ({ value: `${id}`, label: `${name}` }));

  const generalPurposeMovementTypes = orgData.details.movementTypes
    .filter(
      ({ slug }) => !slug.includes("baseball") && !slug.includes("basketball"),
    )
    .map(({ id, name }) => ({ value: `${id}`, label: `${name}` }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groupedMovementTypes = [
    {
      label: "",
      options: standardMovementTypes,
    },
    {
      label: "General Purpose Movements",
      options: generalPurposeMovementTypes,
    },
  ];

  const formatGroupLabel = (data: any) => (
    <div style={{ color: "#001B48" }}>{data.label}</div>
  );

  useEffect(() => {
    const movementTypeId = uploadSessionFormValues.movementType.value;
    if (movementTypeId !== "") {
      setFps(
        movementTypeId === "1" || movementTypeId === "2" // baseball-hitting, baseball-pitching
          ? 240
          : movementTypeId === "101" // vertical-jumping
            ? 120
            : 60,
      );
    }
  }, [uploadSessionFormValues.movementType.value]);

  useEffect(() => {
    if (fps !== null) {
      dispatch(
        storeUploadSessionFormState({
          ...uploadSessionFormValues,
          frameRate: `${fps}`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fps]);

  useEffect(() => {
    setFps(null);
  }, []);

  return (
    <Formik
      initialValues={{
        movementType: uploadSessionFormValues.movementType,
        frameRate: uploadSessionFormValues.frameRate,
      }}
      validationSchema={Yup.object({
        movementType: Yup.object({
          value: Yup.string(),
          label: Yup.string(),
        }),
        frameRate: Yup.string().matches(
          /^(2[89]|[3-9]\d|[1-9]\d{2}|1000|1001|1002)$/,
          "Frame rate must be between 28 and 1002",
        ),
      })}
      onSubmit={() => {
        false;
      }}
    >
      {({ handleSubmit, errors, setFieldValue }) => (
        <form
          className="flex flex-col sm:h-96 w-full items-center mt-4 pb-8 sm:mt-0"
          onSubmit={handleSubmit}
        >
          <fieldset className="w-3/4 sm:pt-10 flex flex-col sm:flex-row justify-center">
            <div
              className="flex flex-col mt-8 sm:mt-0 sm:w-5/12 space-y-8"
              data-testid="movement-form-fields-container"
            >
              <div data-testid="movement-type-container">
                <div className="flex">
                  <label
                    id="movement-type-label"
                    htmlFor="movement-type-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Movement Type
                  </label>
                  <span
                    className="text-red-500 text-sm"
                    data-testid="movement-type-required-asterisk"
                  >
                    *
                  </span>
                </div>
                <Select
                  aria-labelledby="movement-type-label"
                  classNamePrefix="movement-type-select"
                  inputId="movement-type-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={groupedMovementTypes}
                  value={
                    uploadSessionFormValues.movementType.value === ""
                      ? null
                      : uploadSessionFormValues.movementType
                  }
                  placeholder="Select Movement Type"
                  onChange={(movementType) => {
                    if (movementType) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          movementType,
                        }),
                      );
                      setFieldValue("movementType", movementType);
                      setFieldValue("frameRate", fps?.toString());
                    }
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                  formatGroupLabel={formatGroupLabel}
                  maxMenuHeight={200}
                />
              </div>

              <div data-testid="frame-rate-container">
                <div className="flex pb-1">
                  <label
                    htmlFor="frame-rate-input"
                    data-testid="frame-rate-input-label"
                    className="block text-sm font-medium text-gray-500"
                  >
                    Frame Rate
                  </label>
                  <span
                    className="text-red-500 text-sm"
                    data-testid="frame-rate-required-asterisk"
                  >
                    *
                  </span>
                </div>
                <div data-testid="frame-rate-input-container" className="h-9">
                  <div
                    className={joinClassNames(
                      errors.frameRate
                        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500",
                      "flex items-center w-full border rounded-md",
                    )}
                    data-testid="frame-rate-input-container"
                  >
                    <Field
                      type="text"
                      id="frameRate"
                      name="frameRate"
                      value={uploadSessionFormValues.frameRate}
                      onChange={(event: { target: { value: string } }) => {
                        const value = event.target.value;
                        setFieldValue("frameRate", value);
                        dispatch(
                          storeUploadSessionFormState({
                            ...uploadSessionFormValues,
                            frameRate: value,
                          }),
                        );
                      }}
                      placeholder="--"
                      data-testid="frame-rate-input"
                      className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm disabled:opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                      disabled={
                        !fps && uploadSessionFormValues.frameRate === ""
                      }
                    />
                    <span
                      className={joinClassNames(
                        !fps ? "opacity-50" : "",
                        "pr-2 font-medium text-base text-secondary-800 text-right",
                      )}
                      data-testid="fps-label"
                    >
                      FPS
                    </span>
                  </div>
                  {errors.frameRate && (
                    <p
                      className="text-red-500 text-xs mt-0.5"
                      data-testid="frame-rate-error-message"
                    >
                      Frame rate must be between 28 and 1002.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
