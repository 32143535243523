import { flexRender, RowModel } from "@tanstack/react-table";
import React from "react";
import tw from "tailwind-styled-components";
import { joinClassNames } from "../../shared/Functions";

type CustomRowProps = {
  getRowModel: RowModel<Record<string, unknown>>;
  customRowClassName?: string;
  customRowCellClassName?: string;
};

const StyledRow = tw.tr`
  bg-white
  min-w-max
  border-b
  border-gray-200
  h-20
`;

const StyledRowCell = tw.td`
  text-left
  whitespace-nowrap
  text-base
  text-gray-500
  text-sm
`;

export const CustomRow: React.FC<CustomRowProps> = ({
  getRowModel,
  customRowClassName,
  customRowCellClassName,
}: CustomRowProps): JSX.Element => {
  return (
    <tbody>
      {getRowModel.rows.map((row) => {
        return (
          <StyledRow
            className={`${customRowClassName} hover:bg-gray-50`}
            key={row.id}
          >
            {row.getVisibleCells().map((cell, index) => {
              return (
                <StyledRowCell
                  key={cell.id}
                  className={joinClassNames(
                    index === 0 ? "pl-6" : "",
                    customRowCellClassName,
                  )}
                  style={{ width: cell.column.getSize() }}
                >
                  <div className="">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </StyledRowCell>
              );
            })}
          </StyledRow>
        );
      })}
    </tbody>
  );
};
