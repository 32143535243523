import { Disclosure } from "@headlessui/react";
import { InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";

import { CustomTooltip, Segment, SegmentDetails } from "../..";
import { useAppSelector } from "../../../redux/hooks";
import { joinClassNames } from "../../../shared/Functions";
import { CustomAnalysisFormValues } from "../../../types";

type PopulationSegmentProps = {
  shouldShowSegments: boolean;
  populationSegmentDefined: boolean;
  setPopulationSegmentDefined: React.Dispatch<React.SetStateAction<boolean>>;
  values: CustomAnalysisFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  editPopulationSegment: boolean;
  setEditPopulationSegment: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  dirty: boolean;
};

export const PopulationSegment: React.FC<PopulationSegmentProps> = ({
  shouldShowSegments,
  populationSegmentDefined,
  setPopulationSegmentDefined,
  values,
  setFieldValue,
  setFieldTouched,
  editPopulationSegment,
  setEditPopulationSegment,
  isValid,
  dirty,
}: PopulationSegmentProps): JSX.Element => {
  // Redux calls
  const customAnalysisFormState = useAppSelector(
    (state) => state.customAnalysisForm,
  );
  const customAnalysisState = useAppSelector((state) => state.customAnalysis);

  const { populationSegmentData } = customAnalysisState;

  return (
    <div
      className={joinClassNames(
        !shouldShowSegments ? "opacity-50 pointer-events-none" : "",
        "block min-w-max mt-8 border border-gray-200 rounded-lg shadow",
      )}
      data-testid="population-segment-container"
    >
      <Disclosure
        defaultOpen={
          customAnalysisFormState.populationSegment.criteria.length > 0
            ? true
            : false
        }
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={joinClassNames(
                open ? "rounded-t-md" : "rounded-md",
                "p-4 flex bg-gray-50 w-full items-center justify-between",
              )}
              data-testid="define-population-segment-header"
            >
              <div className="flex">
                <PlusIcon className="w-6 text-secondary-500 mr-4" />
                <p className="text-gray-900 text-2xl">
                  <span
                    className={joinClassNames(
                      populationSegmentDefined ? "hidden" : "",
                    )}
                  >
                    Define
                  </span>{" "}
                  Population Segment{" "}
                  <span className="text-gray-300">(optional)</span>
                </p>
                <InformationCircleIcon
                  className="w-6 text-gray-500 ml-5"
                  id="tooltip-anchor-population"
                  data-tooltip-id="tooltip-anchor-population"
                />
                <CustomTooltip
                  anchorId={"tooltip-anchor-population"}
                  type="population"
                  info="Define the Population Segment for further analysis and comparison."
                  place={"right"}
                />
              </div>
              {populationSegmentDefined && (
                <div
                  className="flex space-x-6"
                  data-testid="population-segment-numbers-container"
                >
                  <p
                    className="text-2xl"
                    data-testid="population-segment-player-count"
                  >
                    <span className="text-secondary-900">
                      {populationSegmentData.numPlayers}
                    </span>{" "}
                    <span className="text-gray-900">Players</span>
                  </p>
                  <p
                    className="text-2xl"
                    data-testid="population-segment-movement-count"
                  >
                    <span className="text-secondary-900">
                      {populationSegmentData.numMovements}
                    </span>{" "}
                    <span className="text-gray-900">Movements</span>
                  </p>
                </div>
              )}
            </Disclosure.Button>
            <Disclosure.Panel className="rounded-b-md border-t border-t-gray-200">
              {!populationSegmentDefined ? (
                <Segment
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  segmentType={"population"}
                  movementTypeId={customAnalysisFormState.movementType.id}
                  setPopulationSegmentDefined={setPopulationSegmentDefined}
                  editPopulationSegment={editPopulationSegment}
                  setEditPopulationSegment={setEditPopulationSegment}
                  isValid={isValid}
                  dirty={dirty}
                />
              ) : (
                <SegmentDetails
                  segmentType={"population"}
                  setPopulationSegmentDefined={setPopulationSegmentDefined}
                  setEditPopulationSegment={setEditPopulationSegment}
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
