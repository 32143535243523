import React from "react";
import { OptionTypeBase } from "react-select";

import { DateFilter } from "./filters/DateFilter";
import { DropdownFilter } from "./filters/DropdownFilter";

export type FilterInfo = {
  type: string;
  placeholder: string;
  filterOptions: OptionTypeBase[];
  columnId: string;
};

type TableFiltersProps = {
  filters: FilterInfo[];
  headerGroups: any[];
};

export const TableFilters: React.FC<TableFiltersProps> = ({
  filters,
  headerGroups,
}: TableFiltersProps): JSX.Element => {
  const getColumnHeader = (id: string) => {
    return headerGroups.find((header) => header.id === id);
  };

  return (
    <div className="flex flex-row items-center w-full">
      {filters.map((filter, index) => {
        return (
          <div key={index}>
            {filter.type === "date" && (
              <DateFilter
                placeholderText={filter.placeholder}
                header={getColumnHeader(filter.columnId)}
              />
            )}
            {filter.type === "dropdown" && (
              <DropdownFilter
                filterOptions={filter.filterOptions}
                placeholderText={filter.placeholder}
                header={getColumnHeader(filter.columnId)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
