import React from "react";

import { StepCircle, StepType } from "..";
import { ReactComponent as GreenCheckCircle } from "../../assets/GreenCheckCircle.svg";
import { joinClassNames } from "../../shared/Functions";

export type ModalStepProgressBarProps = {
  currentStep: number;
  steps: StepType[];
};

export const ModalStepProgressBar: React.FC<ModalStepProgressBarProps> = ({
  currentStep,
  steps,
}) => {
  return (
    <div
      className="flex items-center justify-evenly py-5 bg-gray-100 w-full"
      data-testid="modal-step-progress-container"
    >
      {steps.map((step, index) => {
        const stepNumber = index + 1;
        return (
          <div
            className={`flex flex-col sm:w-auto sm:flex-row items-center sm:pl-4 md:pl-6 lg:pl-8 xl:pl-12`}
            key={index}
            data-testid={`step-${stepNumber}-container`}
          >
            {currentStep === stepNumber ? (
              <StepCircle
                active={true}
                number={stepNumber}
                data-testid={`step-${stepNumber}-active-icon`}
              />
            ) : currentStep < stepNumber ? (
              <StepCircle
                active={false}
                number={stepNumber}
                data-testid={`step-${stepNumber}-inactive-icon`}
              />
            ) : (
              <GreenCheckCircle />
            )}
            <h5
              id={`step-${stepNumber}-title`}
              data-testid={`step-${stepNumber}-title`}
              className={joinClassNames(
                currentStep === stepNumber
                  ? "text-secondary-800"
                  : currentStep > stepNumber
                    ? "text-gray-900"
                    : "text-gray-400",
                "sm:pl-4 font-bold text-[12px] sm:text-sm mt-1 sm:mt-0 text-center sm:text-left",
              )}
            >
              {step.title}
            </h5>
          </div>
        );
      })}
    </div>
  );
};
