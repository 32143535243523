import { S3Info } from "./apiTypes";

export enum UploadFileStatusEnum {
  Pending = "PENDING",
  Complete = "COMPLETE",
  Error = "ERROR",
  Canceled = "CANCELED",
}
export type UploadFileInfo = {
  playerName: string;
  /* Provided/created by the frontend when the file is uploaded */
  fileId: number;
  fileName: string;
  /* Provided by the API after the upload session form is submitted - this is the file ID of this file in the database*/
  sessionFileId: number;
  /* Provided by the API after the upload session form is submitted - this is the upload session ID in the database */
  uploadSessionId: number;
  uploadStatus: UploadFileStatusEnum;
  uploadProgress: number;
  info: S3Info[];
};

export interface UploadFile extends File {
  status?: UploadFileStatusEnum;
  fileId?: number;
}

export type UploadSession = {
  sessionDate: string;
  playerId: string;
  uploadSessionId: number;
  finished: boolean;
  preSignedUrls: Record<string, unknown>[];
  uploadedFiles: UploadFileInfo[];
};

export type UploadProgress = {
  fileName: string | undefined;
  uploadProgress: number;
  uploadSessionId: number;
};

export type AbortControllerInfo = {
  fileName: string;
  abortController: AbortController;
};

export type Uploads = {
  uploads: UploadSession[];
};
