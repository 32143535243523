import { Header } from "@tanstack/react-table";
import React, { useState } from "react";
import Select, { OptionTypeBase } from "react-select";

import { DateRangePicker, MultiDatePicker } from "../..";
import {
  customSelectStyles,
  dateRangeOptions,
} from "../../../shared/Constants";
import { CustomDropdownWithCalendar } from "../../shared/CustomSelectComponents";

type DateRangeSelectProps = {
  placeholderText: string;
  header: Header<Record<string, unknown>, unknown>;
};

export const filterDateColumn = (
  row: { getValue: (arg0: any) => any },
  columnId: string,
  filterValue: string | any[],
): any => {
  const cellValue = row.getValue(columnId);

  if (typeof filterValue === "string") {
    if (filterValue.includes(" - ")) {
      const dateRange = filterValue.split(" - ");
      const dates = dateRange.map((date) => new Date(date).toISOString());
      const [start, end] = dates;
      if (cellValue >= start && cellValue <= end) {
        return true;
      } else {
        return false;
      }
    } else if (cellValue !== new Date(filterValue).toISOString()) {
      return false;
    } else {
      return true;
    }
  } else if (Array.isArray(filterValue)) {
    if (filterValue.length === 1) {
      const date = filterValue[0].toISOString();
      if (cellValue !== date) {
        return false;
      }
    } else if (filterValue.length === 2) {
      const [start, end] = filterValue;
      const cellDate = new Date(cellValue);
      if (start && !end) {
        return cellDate.getTime() >= start.getTime();
      } else if (start && end) {
        const dates = filterValue.map((date: any) => date.toISOString());
        const startValue = start.toISOString();
        const endValue = end.toISOString();
        if (cellValue >= startValue && cellValue <= endValue) {
          return true;
        } else if (startValue >= endValue && dates.includes(cellValue)) {
          return true;
        } else {
          return false;
        }
      }
    } else if (filterValue.length > 2) {
      const multipleDates = filterValue.map((date: any) => date.toISOString());
      if (multipleDates.includes(cellValue)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
};

export const DateFilter: React.FC<DateRangeSelectProps> = ({
  placeholderText,
  header,
}: DateRangeSelectProps) => {
  const [dateFilter, setDateFilter] = useState<string>("");
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);

  const [optionDisplayed, setOptionDisplayed] = useState<OptionTypeBase>({
    value: "",
    label: "",
  });

  const column = header?.column;

  return (
    <div className="flex ml-2">
      <div className="w-40" data-testid="date-filter-container">
        <Select
          name="date-filter"
          aria-labelledby="date-filter-aria-label"
          inputId="date-filter"
          id="date-filter-select"
          classNamePrefix="date-filter-select"
          options={[{ value: "", label: "All Dates" }, ...dateRangeOptions]}
          value={optionDisplayed.value !== "" ? optionDisplayed : null}
          isSearchable={false}
          styles={customSelectStyles}
          components={{
            DropdownIndicator: CustomDropdownWithCalendar,
            IndicatorSeparator: () => null,
          }}
          placeholder={placeholderText}
          onChange={(option) => {
            setDateFilter(option.value);
            setOptionDisplayed(option);
            if (option.value === "Custom Range") {
              column?.setFilterValue("");
            } else if (option.value === "Individual Dates") {
              column?.setFilterValue("");
            } else {
              column?.setFilterValue(option.value);
            }
          }}
        />
      </div>
      {dateFilter === "Custom Range" && (
        <div className="ml-2 w-64">
          <DateRangePicker
            placeholderText="Select Range"
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            column={column}
            setDateFilter={setDateFilter}
            setOptionDisplayed={setOptionDisplayed}
          />
        </div>
      )}
      {dateFilter === "Individual Dates" && (
        <div className="ml-2 w-[228px]">
          <MultiDatePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            column={column}
            setDateFilter={setDateFilter}
            setOptionDisplayed={setOptionDisplayed}
          />
        </div>
      )}
    </div>
  );
};
