import {
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  customAnalysisExtendedApi,
  emptyApi,
  orgExtendedApi,
  playerExtendedApi,
  reportExtendedApi,
  sessionExtendedApi,
  uploadExtendedApi,
} from "../services";
import {
  AwsCredentials,
  awsCredentialsExtendedApi,
} from "../services/awsCredentials";
import { playerGroupExtendedApi } from "../services/playerGroup";
import {
  CustomAnalysisFormValues,
  Org,
  Players,
  Sessions,
  Uploads,
  UploadSessionFormValues,
} from "../types";
import { awsCredentialsSlice } from "./slices/awsCredentialsSlice";

import { customAnalysisFormSlice } from "./slices/customAnalysisFormSlice";
import {
  CustomAnalysis,
  customAnalysisSlice,
} from "./slices/customAnalysisSlice";
import {
  DataExportProgressModal,
  dataExportProgressSlice,
} from "./slices/dataExportProgressSlice";
import { DataExport, dataExportSlice } from "./slices/dataExportSlice";
import { orgSlice } from "./slices/orgSlice";
import { playerGroupSlice } from "./slices/playerGroupSlice";
import { playerSlice } from "./slices/playerSlice";
import { sessionSlice } from "./slices/sessionSlice";
import { uploadSessionFormSlice } from "./slices/uploadSessionFormSlice";
import { uploadSessionSlice } from "./slices/uploadSessionSlice";
import { AuthUser, userSlice } from "./slices/userSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const user = userSlice.reducer;
const sessions = sessionSlice.reducer;
const players = playerSlice.reducer;
const org = orgSlice.reducer;
const uploadSession = uploadSessionSlice.reducer;
const uploadSessionForm = uploadSessionFormSlice.reducer;
const api = emptyApi.reducer;
const customAnalysisForm = customAnalysisFormSlice.reducer;
const customAnalysis = customAnalysisSlice.reducer;
const playerGroup = playerGroupSlice.reducer;
const awsCredentials = awsCredentialsSlice.reducer;
const dataExport = dataExportSlice.reducer;
const dataExportProgress = dataExportProgressSlice.reducer;

const appReducer = combineReducers({
  user,
  sessions,
  players,
  org,
  uploadSession,
  uploadSessionForm,
  api,
  customAnalysisForm,
  customAnalysis,
  playerGroup,
  awsCredentials,
  dataExport,
  dataExportProgress,
});

const rootReducer = (
  state:
    | CombinedState<{
        user: AuthUser;
        sessions: Sessions;
        players: Players;
        org: Org;
        uploadSession: Uploads;
        uploadSessionForm: UploadSessionFormValues;
        api: any;
        customAnalysisForm: CustomAnalysisFormValues;
        customAnalysis: CustomAnalysis;
        playerGroup: any;
        awsCredentials: AwsCredentials;
        dataExport: DataExport;
        dataExportProgress: DataExportProgressModal;
      }>
    | undefined,
  action: AnyAction,
) => {
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    emptyApi.reducerPath,
    playerExtendedApi.reducerPath,
    orgExtendedApi.reducerPath,
    sessionExtendedApi.reducerPath,
    reportExtendedApi.reducerPath,
    customAnalysisExtendedApi.reducerPath,
    playerGroupExtendedApi.reducerPath,
    awsCredentialsExtendedApi.reducerPath,
    uploadExtendedApi.reducerPath,
  ],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

/** Handles all async errors by default, taken from https://redux-toolkit.js.org/rtk-query/usage/error-handling */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (
      action.payload.data &&
      action.payload.data.detail &&
      action.payload.data.detail === "Could not validate credentials"
    ) {
      localStorage.setItem("isAuthenticated", "false");
    } else if (
      action.meta.arg.endpointName === "getSessionsByPlayerId" &&
      action.payload.data &&
      action.payload.data.detail === "No sessions found"
    ) {
      console.warn("No sessions found for the selected player");
    } else if (
      action.meta.arg.endpointName === "createPlayer" &&
      action.payload.status === 409
    ) {
      console.warn("Player already exists");
    } else if (action.meta.arg.endpointName === "createPlayer") {
      console.warn("There was an issue creating the player");
    } else if (action.meta.arg.endpointName === "editPlayer") {
      console.warn("There was an issue editing the player");
    } else if (action.meta.arg.endpointName === "postSessionUpload") {
      console.warn("There was an issue uploading the session");
    } else if (
      (action.meta.arg.endpointName === "getReportsByOrgPlayerId" ||
        action.meta.arg.endpointName === "getReportsBySessionId") &&
      action.payload.data &&
      action.payload.data.detail === "No reports found for the given parameters"
    ) {
      console.warn("Unable to find reports for the selected player");
    } else if (action.meta.arg.endpointName === "checkResetToken") {
      console.warn("There was an issue checking the password reset token");
    } else {
      console.warn(
        "Rejected action, problem with RTK Query results: ",
        action.error,
      );
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [],
        warnAfter: 100,
      },
      immutableCheck: { warnAfter: 150 },
    }).concat(emptyApi.middleware, rtkQueryErrorLogger),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
