import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React from "react";
import Select from "react-select";

import {
  CustomDropdownWithChevron,
  CustomMenuWithSearch,
  CustomOptionWithCheckbox,
} from "../../../components/shared/CustomSelectComponents";
import { customSelectStyles } from "../../../shared/Constants";
import {
  CriteriaDropdownProps,
  CustomAnalysisDropdownProps,
  MocapTypeDropdownProps,
  PitchTypeDropdownProps,
  PlayerDropdownProps,
  PlayerGroupDropdownProps,
} from "../../../types";

const handleCriteriaSelection = (
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  segmentType: string,
  index: number,
  option: any,
  dispatch: any,
  updateSegmentCriteria: ActionCreatorWithPayload<
    any,
    "customAnalysisForm/updateSegmentCriteria"
  >,
) => {
  setFieldValue(`${segmentType}Segment.criteria[${index}].option`, option);
  dispatch(
    updateSegmentCriteria({
      segmentType,
      index,
      key: "option",
      value: option,
    }),
  );
};

export const CriteriaDropdown: React.FC<CriteriaDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
  selectFieldOptions,
}: CriteriaDropdownProps) => {
  return (
    <div
      className="w-60"
      data-testid={`${segmentType}-criteria-dropdown-container-${index}`}
    >
      <Select
        name={`${segmentType}-criteria-dropdown-${index}`}
        aria-labelledby={`${segmentType}-criteria-dropdown-aria-label-${index}`}
        inputId={`${segmentType}-criteria-dropdown-${index}`}
        options={selectFieldOptions.sort((a, b) =>
          a.label < b.label ? -1 : 1,
        )}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].field
            : null
        }
        placeholder="Select Criteria"
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        onChange={(field) => {
          if (field) {
            setFieldValue(
              `${segmentType}Segment.criteria[${index}].field`,
              field,
            );
            dispatch(
              updateSegmentCriteria({
                segmentType,
                index,
                key: "field",
                value: field,
              }),
            );
          }
          setFieldValue(`${segmentType}Segment.criteria[${index}].comparison`, {
            value: "",
            label: "",
          });
          dispatch(
            updateSegmentCriteria({
              segmentType,
              index,
              key: "comparison",
              value: null,
            }),
          );
          setFieldValue(`${segmentType}Segment.criteria[${index}].option`, {
            value: "",
            label: "",
          });
          dispatch(
            updateSegmentCriteria({
              segmentType,
              index,
              key: "option",
              value: null,
            }),
          );
        }}
      />
    </div>
  );
};

export const ComparisonDropdown: React.FC<CustomAnalysisDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
}: CustomAnalysisDropdownProps) => {
  const determineComparisonOptions = (i: number) => {
    if (
      currentSegmentState.criteria[i] &&
      (currentSegmentState.criteria[i].field.value === "pitchType" ||
        currentSegmentState.criteria[i].field.value === "mocapPitchType" ||
        currentSegmentState.criteria[i].field.value === "mocapType" ||
        currentSegmentState.criteria[i].field.value === "playerGroup")
    ) {
      return [{ value: "is", label: "is" }];
    } else if (
      currentSegmentState.criteria[i] &&
      (currentSegmentState.criteria[i].field.value === "horizontalBreak" ||
        currentSegmentState.criteria[i].field.value === "verticalBreak" ||
        currentSegmentState.criteria[i].field.value ===
          "inducedVerticalBreak" ||
        currentSegmentState.criteria[i].field.value === "releaseSpeed")
    ) {
      return [
        { value: "lessThan", label: "<" },
        { value: "greaterThan", label: ">" },
        { value: "lessThanOrEqual", label: "<=" },
        { value: "greaterThanOrEqual", label: ">=" },
      ];
    } else if (
      currentSegmentState.criteria[i] &&
      currentSegmentState.criteria[i].field.value === "player"
    ) {
      return [{ value: "includes", label: "includes" }];
    } else {
      return [];
    }
  };

  return (
    <div
      className="w-28 mx-4"
      data-testid={`${segmentType}-comparison-dropdown-container-${index}`}
    >
      <Select
        name={`${segmentType}-comparison-dropdown-${index}`}
        aria-labelledby={`${segmentType}-comparison-dropdown-aria-label-${index}`}
        inputId={`${segmentType}-comparison-dropdown-${index}`}
        options={determineComparisonOptions(index)}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].comparison
            : null
        }
        placeholder="Select..."
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        onChange={(comparison) => {
          if (comparison) {
            setFieldValue(
              `${segmentType}Segment.criteria[${index}].comparison`,
              comparison,
            );
            dispatch(
              updateSegmentCriteria({
                segmentType,
                index,
                key: "comparison",
                value: comparison,
              }),
            );
          }
        }}
      />
    </div>
  );
};

export const PlayerDropdown: React.FC<PlayerDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
  playerOptions,
  inputValue,
  setInputValue,
  isFocused,
  setIsFocused,
}: PlayerDropdownProps) => {
  return (
    <div
      className="min-w-[15rem] max-w-3xl"
      data-testid={`${segmentType}-player-dropdown-container`}
    >
      <Select
        name={`${segmentType}-player-dropdown`}
        aria-labelledby={`${segmentType}-player-dropdown-aria-label`}
        inputId={`${segmentType}-player-dropdown`}
        options={playerOptions}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].option
            : null
        }
        isSearchable={false}
        isMulti
        styles={customSelectStyles}
        components={{
          Option: CustomOptionWithCheckbox,
          MenuList: CustomMenuWithSearch,
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        inputValue={inputValue}
        onMenuInputFocus={() => setIsFocused(true)}
        onInputChange={(val) => setInputValue(val)}
        placeholder={"Select Players"}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={(option) => {
          if (option) {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              option,
              dispatch,
              updateSegmentCriteria,
            );
            setIsFocused(false);
          }
        }}
        {...{
          menuIsOpen: isFocused || undefined,
          isFocused: isFocused || undefined,
        }}
      />
    </div>
  );
};

export const PitchTypeDropdown: React.FC<PitchTypeDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
  pitchTypeOptions,
  mocap,
}: PitchTypeDropdownProps) => {
  return (
    <div
      className="w-60"
      data-testid={`${segmentType}-${
        mocap ? "mocap-pitch" : "pitch"
      }-type-dropdown-container`}
    >
      <Select
        name={`${segmentType}-${mocap ? "mocap-pitch" : "pitch"}-type-dropdown`}
        aria-labelledby={`${segmentType}-${
          mocap ? "mocap-pitch" : "pitch"
        }-type-dropdown-aria-label`}
        inputId={`${segmentType}-${
          mocap ? "mocap-pitch" : "pitch"
        }-type-dropdown`}
        options={pitchTypeOptions}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].option
            : null
        }
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        placeholder={mocap ? "Select Mocap Pitch Type" : "Select Pitch Type"}
        onChange={(option) => {
          if (option) {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              option,
              dispatch,
              updateSegmentCriteria,
            );
          }
        }}
      />
    </div>
  );
};

export const MocapTypeDropdown: React.FC<MocapTypeDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  setFieldTouched,
  dispatch,
  updateSegmentCriteria,
  mocapTypeOptions,
}: MocapTypeDropdownProps): JSX.Element => {
  return (
    <div
      className="w-60"
      data-testid={`${segmentType}-mocap-type-dropdown-container`}
    >
      <Select
        name={`${segmentType}-mocap-type-dropdown`}
        aria-labelledby={`${segmentType}-mocap-type-dropdown-aria-label`}
        inputId={`${segmentType}-mocap-type-dropdown`}
        options={mocapTypeOptions}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].option
            : null
        }
        placeholder="Select Mocap Type"
        isSearchable={false}
        onChange={(option) => {
          if (option) {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              option,
              dispatch,
              updateSegmentCriteria,
            );
          }
        }}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        onBlur={() => {
          setFieldTouched("mocapType", true);
        }}
      />
    </div>
  );
};

export const PlayerGroupDropdown: React.FC<PlayerGroupDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
  playerGroupOptions,
  setPlayerGroupId,
}: PlayerGroupDropdownProps) => {
  return (
    <div
      className="w-60"
      data-testid={`${segmentType}-player-group-dropdown-container`}
    >
      <Select
        name={`${segmentType}-player-group-dropdown`}
        aria-labelledby={`${segmentType}-player-group-dropdown-aria-label`}
        inputId={`${segmentType}-player-group-dropdown`}
        options={playerGroupOptions}
        value={
          currentSegmentState.criteria[index]
            ? currentSegmentState.criteria[index].option
            : null
        }
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        placeholder="Select Player Group"
        onChange={(option) => {
          if (option) {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              option,
              dispatch,
              updateSegmentCriteria,
            );
            setPlayerGroupId(option.id);
          }
        }}
      />
    </div>
  );
};

export const PitchCharacteristicInches: React.FC<
  CustomAnalysisDropdownProps
> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
}: CustomAnalysisDropdownProps) => {
  return (
    <div
      data-testid={`${segmentType}-pitch-characteristic-inches-container`}
      className="h-full w-60"
    >
      <div className="h-full flex items-center w-full rounded-[4px] border border-[hsl(0, 0%, 80%)] focus-within:border-2 focus-within:border-indigo-500 focus:outline-none">
        <input
          type="number"
          id="pitchCharacteristicInches"
          name="pitchCharacteristicInches"
          placeholder="Units of inches..."
          value={
            currentSegmentState.criteria[index].option
              ? currentSegmentState.criteria[index].option.value
              : ""
          }
          data-testid={`${segmentType}-pitch-characteristic-inches-input`}
          className="font-medium text-base w-full rounded-md text-gray-900 h-8 py-0.5 border-none sm:text-sm placeholder-gray-400"
          onChange={(e) => {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              { label: "in", value: e.target.value },
              dispatch,
              updateSegmentCriteria,
            );
          }}
        />
        <span
          className="pr-2 font-medium text-base text-secondary-800 text-right"
          data-testid={`${segmentType}-pitch-characteristic-inches-label`}
        >
          in
        </span>
      </div>
    </div>
  );
};

export const PitchCharacteristicMPH: React.FC<CustomAnalysisDropdownProps> = ({
  segmentType,
  currentSegmentState,
  index,
  setFieldValue,
  dispatch,
  updateSegmentCriteria,
}: CustomAnalysisDropdownProps) => {
  return (
    <div
      data-testid={`${segmentType}-pitch-characteristic-mph-container`}
      className="h-full w-60"
    >
      <div className="h-full flex items-center w-full rounded-[4px] border border-[hsl(0, 0%, 80%)] focus-within:border-2 focus-within:border-indigo-500 focus:outline-none">
        <input
          type="number"
          id="pitchCharacteristicMph"
          name="pitchCharacteristicMph"
          value={
            currentSegmentState.criteria[index].option
              ? currentSegmentState.criteria[index].option.value
              : ""
          }
          placeholder="Speed in mph..."
          data-testid={`${segmentType}-pitch-characteristic-mph-input`}
          className="font-medium text-base w-full rounded-md text-gray-900 h-8 py-0.5 border-none sm:text-sm placeholder-gray-400"
          onChange={(e) => {
            handleCriteriaSelection(
              setFieldValue,
              segmentType,
              index,
              { label: "mph", value: e.target.value },
              dispatch,
              updateSegmentCriteria,
            );
          }}
        />
        <span
          className="pr-2 font-medium text-base text-secondary-800 text-right"
          data-testid={`${segmentType}-pitch-characteristic-mph-label`}
        >
          mph
        </span>
      </div>
    </div>
  );
};
