import * as FullStory from "@fullstory/browser";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  CustomModal,
  DesktopSidebar,
  LoadingSpinner,
  NameDialog,
  PageHeader,
  StepType,
} from "./components";
import AppContext from "./context";
import {
  CustomAnalysisForm,
  DataExportProgressModal,
  FileUploadProgressModal,
  HelpScoutBeacon,
  PlayerGroupModal,
} from "./features";
import {
  AggregateReportGroup,
  Analyze,
  CompletedSessions,
  Develop,
  ErrorPage,
  HomeDashboard,
  PlayerDetails,
  Players,
  ProcessingSessions,
  Reports,
  SessionDetails,
  Sessions,
} from "./pages";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { customAnalysisFormSlice } from "./redux/slices/customAnalysisFormSlice";
import { customAnalysisSlice } from "./redux/slices/customAnalysisSlice";
import { dataExportProgressSlice } from "./redux/slices/dataExportProgressSlice";
import { dataExportSlice } from "./redux/slices/dataExportSlice";
import { uploadSessionFormSlice } from "./redux/slices/uploadSessionFormSlice";
import { clearUserStateAsync, userSlice } from "./redux/slices/userSlice";
import { useAuth0 } from "./shared/Auth0Utility";
import {
  emptyPlayerFormValues,
  emptyProgressModalState,
} from "./shared/Constants";
import {
  AbortControllerInfo,
  DialogStateType,
  PlayerFormValues,
  UploadFile,
} from "./types";
declare global {
  interface Window {
    Beacon: any;
    userGuiding: any;
  }
}

export const App: React.FC = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const { push } = useHistory();

  // State and AppContext
  const [modalState, setModalState] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [modalStepList, setModalStepList] = useState<Array<StepType>>([]);
  const [modalSingleStep, setModalSingleStep] = useState<JSX.Element>();
  const [modalTitle, setModalTitle] = useState("");
  const [displayBack, setDisplayBack] = useState(false);
  const [uploadProgressState, setUploadProgressState] = useState(
    emptyProgressModalState,
  );
  const [exportProgressState, setExportProgressState] = useState(
    emptyProgressModalState,
  );
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [abortControllers, setAbortControllers] = useState<
    AbortControllerInfo[]
  >([]);
  const [dialogState, setDialogState] = useState<DialogStateType>({
    shouldOpen: false,
    type: "",
  });
  const [playerIdToRetrieve, setPlayerIdToRetrieve] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [playerFormValues, setPlayerFormValues] = useState<PlayerFormValues>(
    emptyPlayerFormValues,
  );

  // Redux calls
  const dataExportFiles = useAppSelector(
    (state) => state.dataExportProgress.files,
  );

  // RTK Slice actions
  const dispatch = useAppDispatch();
  const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;
  const { clearCustomAnalysisFormState } = customAnalysisFormSlice.actions;
  const { clearCustomAnalysisState } = customAnalysisSlice.actions;
  const { clearDataExport } = dataExportSlice.actions;
  const { clearDataExportFiles } = dataExportProgressSlice.actions;
  const { setUserCredentials } = userSlice.actions;

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user && !isAuthenticated) {
        await dispatch(clearUserStateAsync());
        await loginWithRedirect();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, user]);

  useEffect(() => {
    if (location.pathname === "/login") {
      push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(setUserCredentials(user));

      // If env-stage or env-prod, identify user with Fullstory and UserGuiding.
      if (
        process.env.REACT_APP_RUN_ENV === "env-stage" ||
        process.env.REACT_APP_RUN_ENV === "env-prod"
      ) {
        FullStory.identify(
          process.env.REACT_APP_RUN_ENV + "-" + String(user.sub),
          {
            displayName: user.name,
            email: user.email,
          },
        );
        window.userGuiding.identify(user.sub, {
          name: user.name,
          email: user.email,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  useEffect(() => {
    const currentProgressModalState: string | null =
      sessionStorage.getItem("showUploadProgress");
    const currentProgressModalTitle: string | null = sessionStorage.getItem(
      "uploadProgressTitle",
    );
    if (currentProgressModalState === "true" && currentProgressModalTitle) {
      setUploadProgressState({
        shouldOpen: true,
        title: currentProgressModalTitle,
      });
    }
  }, []);

  const closeModal = () => {
    setModalState(false);
    if (modalStepList.length) {
      dispatch(clearUploadSessionFormState());
    }
    const titleNode = document.querySelector("#step-1-title");
    if (modalStepList.length === 3 && titleNode) {
      titleNode.innerHTML = `<div>Select Player</div>`;
    }
    if (modalTitle.includes("Define Export")) {
      dispatch(clearDataExport());
    }
    setCurrentStep(1);
    setModalStepList([]);
    setModalSingleStep(undefined);
    setPlayerFormValues(emptyPlayerFormValues);
  };

  useEffect(() => {
    if (uploadInProgress || exportInProgress) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [exportInProgress, uploadInProgress]);

  useEffect(() => {
    if (
      !exportInProgress &&
      !exportProgressState.shouldOpen &&
      dataExportFiles.length > 0
    ) {
      dispatch(clearDataExportFiles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataExportFiles.length,
    exportInProgress,
    exportProgressState.shouldOpen,
  ]);

  useEffect(() => {
    if (location.pathname !== "/request-analysis") {
      dispatch(clearCustomAnalysisFormState());
      dispatch(clearCustomAnalysisState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {isAuthenticated ? (
        <AppContext.Provider
          value={{
            uploadProgressState,
            setUploadProgressState,
            exportProgressState,
            setExportProgressState,
            modalState,
            setModalState,
            modalTitle,
            setModalTitle,
            setDisplayBack,
            modalStepList,
            setModalStepList,
            modalSingleStep,
            setModalSingleStep,
            currentStep,
            setCurrentStep,
            uploadFiles,
            setUploadFiles,
            abortControllers,
            setAbortControllers,
            dialogState,
            setDialogState,
            playerIdToRetrieve,
            setPlayerIdToRetrieve,
            uploadInProgress,
            setUploadInProgress,
            exportInProgress,
            setExportInProgress,
            loadingDashboard,
            setLoadingDashboard,
            playerFormValues,
            setPlayerFormValues,
          }}
        >
          <div className="h-screen flex overflow-hidden bg-white">
            <Toaster containerStyle={{ zIndex: 999999 }} />
            <div className="flex flex-col h-full w-full overflow-auto">
              <CustomModal
                shouldShowModal={modalState}
                closeModal={closeModal}
                title={modalTitle}
                steps={modalStepList}
                singleStep={modalSingleStep}
              />
              <PlayerGroupModal />
              <Router>
                {(dialogState.type === "analysis" ||
                  dialogState.type === "group") &&
                  dialogState.shouldOpen && <NameDialog />}
                <div
                  className="fixed z-10 h-16 bg-white w-full"
                  data-testid="header-parent-container"
                >
                  <div className="w-full h-full">
                    {user && (
                      <PageHeader
                        user={user}
                        displayBack={displayBack}
                        data-testid="header-component"
                      />
                    )}
                  </div>
                </div>
                <div className="flex h-screen w-full mt-16">
                  <DesktopSidebar />
                  <Switch>
                    <Route exact path={"/"} component={HomeDashboard} />
                    <Route
                      exact
                      path={"/reports/:activeTab?"}
                      component={Reports}
                    />
                    <Route
                      exact
                      path={"/aggregate-group/:reportId"}
                      component={AggregateReportGroup}
                    />
                    <Route exact path={"/sessions"} component={Sessions} />
                    <Route
                      exact
                      path={"/players/:activeTab?"}
                      component={Players}
                    />
                    <Route
                      exact
                      path={"/processing-sessions"}
                      component={ProcessingSessions}
                    />
                    <Route
                      exact
                      path={"/completed-sessions"}
                      component={CompletedSessions}
                    />
                    <Route
                      exact
                      path={"/player/:playerId"}
                      component={PlayerDetails}
                    />
                    <Route
                      exact
                      path={"/session/:sessionId"}
                      component={SessionDetails}
                    />
                    <Route
                      exact
                      path={"/analyze/:activeTab?"}
                      component={Analyze}
                    />
                    <Route
                      exact
                      path={"/request-analysis"}
                      component={CustomAnalysisForm}
                    />
                    <Route
                      exact
                      path={"/develop/:activeTab?"}
                      component={Develop}
                    />
                    <Route exact path={"/error/:code"} component={ErrorPage} />
                    <Redirect to="/error/404" />
                  </Switch>
                </div>
              </Router>
              <FileUploadProgressModal title={uploadProgressState.title} />
              <DataExportProgressModal
                title={
                  exportProgressState.title
                    ? exportProgressState.title
                    : "Preparing Export"
                }
              />
            </div>
            <HelpScoutBeacon />
          </div>
        </AppContext.Provider>
      ) : (
        <div className="h-screen flex justify-center items-center animate-pulse">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
