import {
  ChartBarIcon,
  CommandLineIcon,
  DocumentChartBarIcon,
  HomeIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAppContext } from "../../context";
import { joinClassNames } from "../../shared/Functions";

export type DesktopSidebarProps = {
  navigation: Record<string, any>[];
};

export const DesktopSidebar: React.FC = () => {
  const { loadingDashboard } = useAppContext();

  const location = useLocation();

  const navigation = useMemo(
    () => [
      {
        name: "Home",
        href: "/",
        icon: HomeIcon,
        current: location.pathname === "/",
      },
      {
        name: "Reports",
        href: "/reports",
        icon: DocumentChartBarIcon,
        current:
          location.pathname.includes("/reports") ||
          location.pathname.includes("/aggregate-group/"),
      },
      {
        name: "Sessions",
        href: "/sessions",
        icon: VideoCameraIcon,
        current:
          location.pathname.includes("/sessions") ||
          location.pathname.includes("/processing-sessions") ||
          location.pathname.includes("/completed-sessions"),
      },
      {
        name: "Players",
        href: "/players",
        icon: UserGroupIcon,
        current:
          location.pathname.includes("/players") ||
          location.pathname.includes("/player/"),
      },
      {
        name: "Analyze",
        href: "/analyze",
        icon: ChartBarIcon,
        current:
          location.pathname.includes("/analyze") ||
          location.pathname.includes("/request-analysis"),
      },
      {
        name: "Develop",
        href: "/develop",
        icon: CommandLineIcon,
        current: location.pathname.includes("/develop"),
      },
    ],
    [location.pathname],
  );

  return (
    <div
      className="h-full hidden lg:flex sm:w-24 z-10"
      data-testid="desktop-sidebar-component"
    >
      <div className="flex flex-col w-24 h-full fixed">
        <div className="flex flex-col flex-grow pt-1 pb-4 overflow-y-auto bg-primary-800">
          <nav
            className="mt-2 flex-1 flex flex-col justify-between overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              {navigation.map((item) =>
                loadingDashboard ? (
                  <div
                    key={item.name}
                    className="group flex flex-col items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white text-center opacity-50"
                    data-testid="desktop-sidebar-skeleton"
                  >
                    <item.icon
                      className="flex-shrink-0 h-6 w-6 text-white animate-pulse"
                      data-testid={`desktop-sidebar-${item.name.toLowerCase()}-icon-skeleton`}
                    />
                    <p
                      className="animate-pulse"
                      data-testid={`desktop-sidebar-${item.name.toLowerCase()}-skeleton`}
                    >
                      {item.name}
                    </p>
                  </div>
                ) : (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={joinClassNames(
                      item.current ? "bg-gray-500" : "hover:bg-gray-500",
                      "group flex flex-col items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white text-center",
                    )}
                    aria-current={item.current ? "page" : undefined}
                    id={`desktop-sidebar-${item.name.toLowerCase()}-link`}
                    data-testid={`desktop-sidebar-${item.name.toLowerCase()}-link`}
                  >
                    <item.icon
                      className="flex-shrink-0 h-6 w-6 text-white"
                      aria-hidden="true"
                      data-testid={`desktop-sidebar-${item.name.toLowerCase()}-icon`}
                    />
                    {item.name}
                  </Link>
                ),
              )}
            </div>
            <div className="px-2 pb-16 space-y-1" data-testid="help-container">
              <a
                className="group flex flex-col items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white"
                href="#"
                onClick={() => window.Beacon("open")}
                data-testid="helpscout-link"
              >
                <QuestionMarkCircleIcon
                  className="flex-shrink-0 h-8 w-8 text-white"
                  data-testid="help-icon"
                />
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
