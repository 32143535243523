import React, { useEffect, useRef } from "react";

import { isMobile } from "react-device-detect";
import {
  BlankButton,
  ModalStepProgressBar,
  PrimaryButton,
  SecondaryButton,
  SpinnerButton,
} from "..";
import { useAppContext } from "../../context";
import { submitDataUpload } from "../../features/UploadData/services/ApiUploadFunctions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  usePatchSessionUploadMutation,
  usePostSessionUploadMutation,
} from "../../services";
import { joinClassNames } from "../../shared/Functions";
import { FormTypeEnum } from "../../types";

export type StepProps = {
  goNextStep: () => void;
  goPreviousStep: () => void;
  currentStep: number;
  isLast: boolean;
  isFirst: boolean;
  step: number;
  display: JSX.Element;
  disableNext: boolean;
};

export type StepType = {
  title: string;
  display: JSX.Element;
  disableNext: boolean;
  formType: FormTypeEnum;
};

export type ModalStepProps = {
  steps: StepType[];
};

export const ModalStep: React.FC<ModalStepProps> = ({ steps }) => {
  // State and AppContext
  const {
    setUploadProgressState,
    setModalState,
    uploadFiles,
    setCurrentStep,
    setAbortControllers,
    setUploadInProgress,
    currentStep,
    modalTitle,
  } = useAppContext();
  const [display, setDisplay] = React.useState<JSX.Element>(<></>);
  const [disableNext, setDisableNext] = React.useState<boolean>(true);
  const lastStep = steps.length;

  const stepSelector = useRef<HTMLDivElement>(null);

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  // RTK slice actions
  const dispatch = useAppDispatch();

  // RTK Queries and Mutations
  const [postSessionUpload, { isLoading }] = usePostSessionUploadMutation();
  const [patchSessionUpload] = usePatchSessionUploadMutation();

  const goNextStep = () => {
    const nextStep = currentStep + 1;
    if (steps && nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const goPreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  const dataUploadProps = {
    dispatch,
    uploadSessionFormValues,
    postSessionUpload,
    patchSessionUpload,
    setUploadProgressState,
    setModalState,
    uploadFiles,
    setAbortControllers,
    setUploadInProgress,
    goPreviousStep,
    setCurrentStep,
  };

  const handleUploadAndContinue = () => {
    submitDataUpload({ ...dataUploadProps, uploadMoreMovements: true });
  };

  const handleUploadAndFinish = () => {
    if (currentStep === lastStep && modalTitle.includes("Upload Data")) {
      submitDataUpload({ ...dataUploadProps, uploadMoreMovements: false });
    } else {
      goNextStep();
    }
  };

  useEffect(() => {
    if (steps && steps.length > 0) {
      setDisplay(steps[currentStep - 1].display);
      setDisableNext(steps[currentStep - 1].disableNext);
    }
  }, [currentStep, steps]);

  return (
    <div
      data-testid="multi-step-modal-contents"
      className="w-full relative flex flex-col justify-between h-full"
    >
      <ModalStepProgressBar currentStep={currentStep} steps={steps} />
      <div
        className={joinClassNames(
          isMobile ? "h-full" : "h-[471px]",
          "w-full flex justify-center overflow-scroll",
        )}
        ref={stepSelector}
        data-testid="multi-step-modal-body"
      >
        {display}
      </div>
      <footer
        id="footer-container"
        className={joinClassNames(
          currentStep === 1 ? "justify-end" : "justify-between",
          "flex pt-5 border-t border-gray-200 h-20 py-4 px-6",
        )}
        data-testid="multi-step-modal-footer"
      >
        <BlankButton hidden={currentStep === 1} onClick={goPreviousStep}>
          Back
        </BlankButton>
        <div className="flex items-center">
          {currentStep === lastStep && !isLoading ? (
            <SecondaryButton
              onClick={handleUploadAndContinue}
              disabled={disableNext}
              className={isMobile ? "ml-3" : ""}
            >
              {isMobile ? "Upload & Add" : "Upload & Add Another Movement Type"}
            </SecondaryButton>
          ) : null}
          {isLoading ? (
            <div className="ml-3">
              <SpinnerButton btnText={"Uploading"} />
            </div>
          ) : (
            <PrimaryButton
              onClick={handleUploadAndFinish}
              className="ml-3"
              disabled={disableNext}
            >
              {currentStep === lastStep ? "Upload & Finish" : "Next"}
            </PrimaryButton>
          )}
        </div>
      </footer>
    </div>
  );
};
