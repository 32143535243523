import { camelCaseResponse } from "../shared/Functions";
import { ApiMovementTypesAndTags, ApiOrg } from "../types/apiTypes";
import { emptyApi } from "./emptyApi";

export const orgExtendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrgInfo: builder.query<ApiOrg, void>({
      query: () => `/org`,
      transformResponse: (response: any) => camelCaseResponse(response),
      providesTags: () => [{ type: "Org" }],
    }),
    getOrgMovementTypesAndTags: builder.query<
      ApiMovementTypesAndTags[],
      string
    >({
      query: (id) => `/org_movements?org_id=${id}`,
      transformResponse: (response: any) =>
        processRetrievedMovementTypes(camelCaseResponse(response)),
    }),
  }),
});

export const { useGetOrgInfoQuery, useGetOrgMovementTypesAndTagsQuery } =
  orgExtendedApi;

const processRetrievedMovementTypes = (response: ApiMovementTypesAndTags[]) =>
  response.map((item) => ({
    ...item,
    movementType: {
      name:
        item.movementType.name.includes("Baseball") ||
        item.movementType.name.includes("Basketball")
          ? item.movementType.name.split(" ").slice(1).join(" ")
          : item.movementType.name,
      slug: item.movementType.slug,
      id: item.movementType.id,
    },
  }));
