import { createSlice } from "@reduxjs/toolkit";

export type ApiPlayerGroupSegmentData = {
  groupdId: number;
  repMovementId: string;
  analysisSegmentId: number | null;
  numSessions: number;
  numMovements: number;
  numPlayers: number;
  criteria: {
    domHand?: string;
    sessionIds?: string[];
    mocapTypeId?: number;
    movementTypeId?: number;
    sessionTypeIds?: number[];
    sessionDate?: string[];
    orgPlayerIds?: string[];
    pitchTypes?: string[];
    mocapPitchTypes?: string[];
    sessionDateIn?: string[];
    horizontalBreak?: string[];
    inducedVerticalBreak?: string[];
    relSpeedMph?: string[];
    verticalBreak?: string[];
  };
  orgId: string;
  id: number | null;
};

export type CustomAnalysis = {
  name: string;
  primarySegmentData: ApiPlayerGroupSegmentData;
  populationSegmentData: ApiPlayerGroupSegmentData;
  comparisonSegmentData: ApiPlayerGroupSegmentData;
};

const initialState: CustomAnalysis = {
  name: "",
  primarySegmentData: {
    groupdId: 0,
    repMovementId: "",
    analysisSegmentId: null,
    numSessions: 0,
    numMovements: 0,
    numPlayers: 0,
    criteria: {},
    orgId: "",
    id: null,
  },
  populationSegmentData: {
    groupdId: 0,
    repMovementId: "",
    analysisSegmentId: null,
    numSessions: 0,
    numMovements: 0,
    numPlayers: 0,
    criteria: {},
    orgId: "",
    id: null,
  },
  comparisonSegmentData: {
    groupdId: 0,
    repMovementId: "",
    analysisSegmentId: null,
    numSessions: 0,
    numMovements: 0,
    numPlayers: 0,
    criteria: {},
    orgId: "",
    id: null,
  },
};

export const customAnalysisSlice = createSlice({
  name: "customAnalysis",
  initialState,
  reducers: {
    storeCustomAnalysisState: (state, action) => {
      state.name = action.payload.name;
      state.primarySegmentData = action.payload.primarySegmentData;
      state.populationSegmentData = action.payload.populationSegmentData;
      state.comparisonSegmentData = action.payload.comparisonSegmentData;
    },
    clearCustomAnalysisState: (state) => {
      state = initialState;
      return state;
    },
  },
});
