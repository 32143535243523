import React, { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";

import { ErrorToast } from "../../../components";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import { uploadSessionSlice } from "../../../redux/slices/uploadSessionSlice";
import {
  AbortControllerInfo,
  UploadFile,
  UploadFileStatusEnum,
  UploadSessionFormValues,
} from "../../../types";
import { PostSessionUploadResponse } from "../../../types/apiTypes";
import { ProgressModalState } from "../../../types/sharedTypes";
import { fileChunkSize, uploadFileToS3Bucket } from "./S3UploadFunctions";

type SubmitDataUploadProps = {
  dispatch: any;
  uploadSessionFormValues: UploadSessionFormValues;
  postSessionUpload: any;
  patchSessionUpload: any;
  setUploadProgressState: Dispatch<SetStateAction<ProgressModalState>>;
  setModalState: Dispatch<SetStateAction<boolean>>;
  uploadFiles: UploadFile[];
  setAbortControllers: Dispatch<SetStateAction<AbortControllerInfo[]>>;
  setUploadInProgress: Dispatch<SetStateAction<boolean>>;
  uploadMoreMovements?: boolean;
  goPreviousStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
};

const { storeUploadSessionState } = uploadSessionSlice.actions;
const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;
const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

const errorToast = (message: string) =>
  toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
    id: "uploadError",
    duration: 4000,
  });

const determineNumberOfChunks = (fileSize: number) => {
  let numberOfChunks = 0;
  if (fileSize % fileChunkSize > 0) {
    numberOfChunks += 1;
  }
  numberOfChunks += Math.floor(fileSize / fileChunkSize);
  return numberOfChunks;
};

export const submitDataUpload = ({
  dispatch,
  uploadSessionFormValues,
  postSessionUpload,
  patchSessionUpload,
  setUploadProgressState,
  setModalState,
  uploadFiles,
  setAbortControllers,
  setUploadInProgress,
  uploadMoreMovements,
  goPreviousStep,
  setCurrentStep,
}: SubmitDataUploadProps) => {
  const frameRate = uploadSessionFormValues.frameRate;

  const newUploadSessionFormValues = {
    ...uploadSessionFormValues,
    files: uploadSessionFormValues.files.map(
      (file: { fileSizeBytes: number }) => {
        return {
          ...file,
          frameRate: parseInt(frameRate || "240"),
          numberOfChunks: determineNumberOfChunks(file.fileSizeBytes),
        };
      },
    ),
  };

  const openProgressModal = () => {
    if (!uploadMoreMovements) {
      setModalState(false);
      setCurrentStep(1);
    }

    setUploadInProgress(true);
    const numberOfFiles = uploadSessionFormValues.files.length;
    const filesSingularOrPlural = numberOfFiles === 1 ? "file" : "files";
    setUploadProgressState({
      shouldOpen: true,
      title: `Uploading ${numberOfFiles} ${filesSingularOrPlural}`,
    });
    sessionStorage.setItem("showUploadProgress", "true");
    sessionStorage.setItem(
      "uploadProgressTitle",
      `Uploading ${numberOfFiles} ${filesSingularOrPlural}`,
    );
  };

  postSessionUpload(newUploadSessionFormValues)
    .unwrap()
    .then((response: PostSessionUploadResponse) => {
      if (uploadMoreMovements) {
        dispatch(
          storeUploadSessionFormState({
            ...uploadSessionFormValues,
            movementType: { value: "", label: "" },
            frameRate: "",
            files: [],
            sessionId: response.sessionId,
          }),
        );
        goPreviousStep();
      }
      openProgressModal();
      return dispatch(
        storeUploadSessionState({
          sessionDate: newUploadSessionFormValues.sessionDate,
          playerId: newUploadSessionFormValues.selectedPlayer.value,
          uploadSessionId: response.uploadSessionId,
          preSignedUrls: response.preSignedUrls,
          finished: false,
          uploadedFiles: response.preSignedUrls.map((preSignedUrl: any) => {
            return {
              fileId: preSignedUrl.fileId,
              fileName: preSignedUrl.fileName,
              sessionFileId: preSignedUrl.sessionFileId,
              uploadSessionId: response.uploadSessionId,
              uploadStatus: UploadFileStatusEnum.Pending,
              playerName: newUploadSessionFormValues.selectedPlayer.label,
              info: preSignedUrl.info,
              uploadProgress: 0,
            };
          }),
        }),
      );
    })
    .then(
      (response: {
        payload: { preSignedUrls: any[]; uploadSessionId: number };
      }) => {
        if (response) {
          response.payload.preSignedUrls.forEach((preSignedUrlInfo: any) => {
            const controller: AbortController = new AbortController();
            const abortControllerInfo = {
              abortController: controller,
              fileName: preSignedUrlInfo.fileName,
            };
            setAbortControllers((prev: any) => [...prev, abortControllerInfo]);
            uploadFileToS3Bucket(
              preSignedUrlInfo,
              response.payload.uploadSessionId,
              uploadFiles,
              dispatch,
              patchSessionUpload,
              abortControllerInfo,
            );
          });
        }
      },
    )
    .then(() => {
      if (!uploadMoreMovements) {
        dispatch(clearUploadSessionFormState());
      }
    })
    .catch(() => {
      errorToast("There was an issue uploading the session. Please try again.");
    });
};
