import { Header } from "@tanstack/react-table";
import React, { useState } from "react";
import Select, { OptionTypeBase } from "react-select";

import { CustomDropdownWithChevron } from "../..";
import { customSelectStyles } from "../../../shared/Constants";

type FilterProps = {
  filterOptions: OptionTypeBase[];
  placeholderText: string;
  header: Header<Record<string, unknown>, unknown>;
};

export const DropdownFilter: React.FC<FilterProps> = ({
  header,
  filterOptions,
  placeholderText,
}: FilterProps): JSX.Element => {
  const [filterValue, setFilterValue] = useState<OptionTypeBase>({
    value: "",
    label: "",
  });

  const column = header?.column;

  return (
    <div
      className="flex flex-col justify-center static w-44 h-full ml-4"
      data-testid="dropdown-filter-container"
    >
      <Select
        name="dropdown-filter"
        aria-labelledby="dropdown-filter-aria-label"
        inputId="dropdown-filter"
        id="dropdown-filter-select"
        classNamePrefix="dropdown-filter-select"
        options={filterOptions}
        value={filterValue.value !== "" ? filterValue : null}
        isSearchable={false}
        styles={customSelectStyles}
        placeholder={placeholderText}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        data-testid="dropdown-filter-select"
        onChange={(option) => {
          if (column && option) {
            setFilterValue(option);
            column.setFilterValue(option.value);
          }
        }}
      />
    </div>
  );
};
