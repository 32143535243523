import { createSlice } from "@reduxjs/toolkit";

export type DataExportFile = {
  orgPlayerId: string;
  sessionId: string;
  movementType: { id: string; value: string };
  dataType: { id: string; value: string };
  dataFormat: string;
  sessionDate: string;
  playerName: string;
  status?: string;
  downloadUrl?: string;
};

export type DataExportProgressModal = {
  files: DataExportFile[];
};

const initialState: DataExportProgressModal = {
  files: [],
};

export const dataExportProgressSlice = createSlice({
  name: "dataExport",
  initialState,
  reducers: {
    storeDataExportFiles: (state, action) => {
      state.files = action.payload.files;
    },
    updateDataExportFileStatus: (state, action) => {
      const fileToUpdate = state.files.find((file) => {
        return (
          file.sessionId === action.payload.sessionId &&
          file.dataType.id === action.payload.dataType &&
          file.movementType.id === action.payload.movementType
        );
      });
      if (fileToUpdate) {
        if (action.payload.status === "success") {
          fileToUpdate.status = action.payload.status;
          fileToUpdate.downloadUrl = action.payload.downloadUrl;
        } else if (action.payload.status === "error") {
          fileToUpdate.status = action.payload.status;
        } else {
          fileToUpdate.status = "preparing";
        }
      }
    },
    clearDataExportFiles: (state) => {
      state = initialState;
      return state;
    },
  },
});
