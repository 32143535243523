import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  ColumnOneTableCellSkeleton,
  IconButton,
  Table,
  TableCellSkeleton,
} from "../../../components";
import { columnHelper } from "../../../components/shared/SharedSessionColumns";
import { useAppContext } from "../../../context";
import { useGetAggregateReportGroupsQuery } from "../../../services";
import { useReportsTableFilters } from "../../../shared/Constants";
import { downloadS3Files } from "../../../shared/Functions";

export const AggregateReports: React.FC = (): JSX.Element => {
  // State and AppContext
  const { setDisplayBack } = useAppContext();

  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("completedSessionPageSize") || "10",
  );

  const { data: aggregateReports, isFetching: fetchingReports } =
    useGetAggregateReportGroupsQuery();

  const tableData = useMemo(
    () => (fetchingReports ? Array(10).fill({}) : aggregateReports),
    [fetchingReports, aggregateReports],
  );

  const aggregateReportsColumns = useMemo(
    () => [
      columnHelper.accessor("dateRange", {
        header: "Date Range",
        enableSorting: false,
        size: 600,
        cell: function dateRangeCell(element: any) {
          return (
            <Link
              to={`/aggregate-group/${element.row.original.id}`}
              className="font-medium text-gray-900 hover:underline"
              data-testid="aggregate-report-date-range-link"
            >
              {element.getValue()}
            </Link>
          );
        },
      }),
      columnHelper.accessor("movementType", {
        header: "Movement Type",
        enableSorting: false,
        cell: function movementTypeCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="aggregate-report-movement-type"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("createdAt", {
        header: "Date Created",
        sortingFn: "datetime",
        cell: function dateCell(element: any) {
          return (
            <div
              className="font-medium text-gray-900 cursor-default"
              data-testid="aggregate-report-date-created"
            >
              {format(new Date(element.getValue()), "M/d/yyyy")}
            </div>
          );
        },
      }),
      columnHelper.accessor("totalAggregateAnalysesCount", {
        header: "# of Reports",
        enableSorting: false,
        cell: function numReportsCell(element: any) {
          return (
            <p
              className="font-medium text-gray-900 cursor-default"
              data-testid="aggregate-report-number"
            >
              {element.getValue()}
            </p>
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "",
        enableSorting: false,
        cell: function actionsCell(element: any) {
          const urls = element.row.original.aggregateAnalyses.map(
            (analysis: any) => analysis.reportUrl,
          );
          return (
            <div
              className="flex justify-end pr-8"
              data-testid="aggregate-report-actions-container"
            >
              <IconButton
                onClick={() => downloadS3Files(urls, true)}
                data-testid="download-aggregate-zip-button"
              >
                <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
              </IconButton>
            </div>
          );
        },
      }),
    ],
    [],
  );

  const tableColumns = useMemo(
    () =>
      fetchingReports
        ? aggregateReportsColumns.map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : aggregateReportsColumns,
    [fetchingReports, aggregateReportsColumns],
  );

  useEffect(() => {
    setDisplayBack && setDisplayBack(false);
  }, [setDisplayBack]);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full mt-8">
      <div
        className="block min-w-max"
        data-testid="aggregate-reports-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="aggregate-reports-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingReports}
          customRowClassName="aggregate-reports-table-row"
          filters={useReportsTableFilters()}
        />
      </div>
    </main>
  );
};
